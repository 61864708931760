import { UserContext } from './UserContext.js';

import styles from './InvoicesScreen.module.css';
import helpers from './helpers.js';
import {useState, useEffect, useContext} from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";
import FAQ from "./components/support/FAQ.js";
import InvoiceBasic from "./assets/InvoiceBasic.js";

// import invoice from '../public/invoicePlaceholder.pdf';


const placeholderInvoices = [
  {
    id: "01246",
    status: "paid",
    dueDate: "13/09/23",
    amountDue: 1300,
    xeroPdfLink: ""
  }
]



function InvoicesScreen(props) {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const {user, setUser} = useContext(UserContext);
  const [invoices, setInvoices] = useState(placeholderInvoices);

  useEffect(() => {
    console.log("Fetching Invoices") 
    const fetchInvoices = async () => {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      };
      let response = await fetch(user.backEndURL + "/farmer/invoices", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        console.log(jsonResponse.data);
        let newInvoices = [];
        jsonResponse.data.forEach((invoice) => {
          let invoiceDate = new Date(invoice.due_date)
          let dateString = invoiceDate.getDay() + "/" + (invoiceDate.getMonth()+1) + "/" + invoiceDate.getFullYear();
          let newInvoice = {
            id: invoice._id,
            status: invoice.status,
            dueDate: dateString,
            amountDue: invoice.amount_due,
            xeroPdfLink: invoice.xero_pdf_link
          }
          newInvoices.push(newInvoice);
        })

        /*
         id: "01246",
    status: "paid",
    dueDate: "13/09/23",
    amountDue: 1300,
    xeroPdfLink: ""
        */

        setInvoices(newInvoices);
        console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
      } else {
        console.log("Fetch Subscription Failed...")
      }
      
    }
    fetchInvoices();
  },[])

  console.log(user);

  const renderInvoices = () => {
    let filteredInvoices = [];
    if(selectedFilter === "all"){
      filteredInvoices = invoices;
    }else {
      filteredInvoices = invoices.filter(function (invoice) {
        return invoice.status === selectedFilter 
      }
    );
  }

  const renderStatus = (status) => {
    switch(status){
      case "paid":
        return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Paid</td>
      case "upcoming":
        return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
      case "cancelled":
        return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
      case "late":
        return <td className={styles.statusLate}><span className={styles.statusIcon}>exclamation-circle</span>Late</td>
      default:
          return <td>N/A</td>
    }

  }
 

  return(
    <>
      {filteredInvoices.map(invoice => {
        console.log(invoice.xeroPdfLink)
        return(
          <tr className={styles.tableRow}>
            <td className={styles.dataBold}>#{invoice.id}</td>
            {renderStatus(invoice.status)}
            <td>{invoice.dueDate}</td>
            <td>£ <span className={styles.dataBold}>{invoice.amountDue} </span></td>
            
            <td className={styles.downloadInvoice}>
            <a className={styles.link} href={invoice.xeroPdfLink} rel="noreferrer" target="_blank">
             <span className={styles.statusIcon}>file-download</span> 
            </a> 

              
              {/* <PDFDownloadLink className={styles.link} document={<InvoiceBasic id={invoice.id}/>} fileName={invoice.id}>
              </PDFDownloadLink> */}
            </td>
          </tr>
        ) 
      })
    }
    </>
  )
}
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      {/* <div style={{position: "absolute", height: "100vh", width: "100vw", background: "white", zIndex: 999}}>
        <PDFViewer style={{height: "100%", width: "100%"}}>
          <InvoiceBasic/>
        </PDFViewer>
      </div> */}
      <Nav goToScreen={props.goToScreen} currentScreen={"invoices"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Invoices"} content={"Browse through your invoices. If one of your payments is late, we give you a 3 week grace period. Don’t hesitate to contact us if you have any questions!"}/>
          <div className={"contentGrid"}>
            <Module spanColumn={24} spanRow={5} height={"100%"}>
              <div className={styles.headerRow}>
                <div onClick={() => setSelectedFilter("all")} className={helpers.clx(styles.headerFilter, selectedFilter === "all" ? styles.headerFilterSelected : "")}>
                  All
                </div>
                <div onClick={() => setSelectedFilter("upcoming")} className={helpers.clx(styles.headerFilter, selectedFilter === "upcoming" ? styles.headerFilterSelected : "")}>
                  Upcoming
                </div>
                <div onClick={() => setSelectedFilter("paid")} className={helpers.clx(styles.headerFilter, selectedFilter === "paid" ? styles.headerFilterSelected : "")}>
                  Paid
                </div>
                <div onClick={() => setSelectedFilter("late")} className={helpers.clx(styles.headerFilter, selectedFilter === "late" ? styles.headerFilterSelected : "")}>
                  Late
                </div>
                <div onClick={() => setSelectedFilter("cancelled")} className={helpers.clx(styles.headerFilter, selectedFilter === "cancelled" ? styles.headerFilterSelected : "")}>
                  Cancelled
                </div>
              </div>
              <table className={styles.orderTable}>
                <tr className={styles.orderHeaderRow}>
                  <th>Invoice ID</th>
                  <th>Status</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th>PDF File</th>
                </tr>
                {renderInvoices()}
              </table>

            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicesScreen;
