import React from 'react';
import styles from './DetailsTable.module.css'; //styles


const DetailsTable = (props) => {
    console.log(props.subscription)
    return(
        <div className={styles.detailsTableContainer}> 
            <div className={styles.rowDark}>
                <div className={styles.fieldName}>
                    Farm Name:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.user.details !== "undefined" ? props.user.details.businessName : "John's Dairy Farm"}
                </div>
            </div>
            <div className={styles.rowLight}>
                <div className={styles.fieldName}>
                    Supervisor:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.supervisorName: "Arla"}
                </div>
            </div>
            <div className={styles.rowDark}>
                <div className={styles.fieldName}>
                    Payment Plan:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.payment_plan.charAt(0).toUpperCase() + props.subscription.payment_plan.slice(1) : "Monthly"}
                </div>
            </div>
            <div className={styles.rowLight}>
                <div className={styles.fieldName}>
                    Payment Status:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.business_name : "Up To Date"}N/A
                </div>
            </div>
            <div className={styles.rowDark}>
                <div className={styles.fieldName}>
                    Total Deliveries:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.business_name : "15"}0
                </div>
            </div>
            <div className={styles.rowLight}>
                <div className={styles.fieldName}>
                    Ends On:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.business_name : "12/03/2023"}
                </div>
            </div>
            <div className={styles.rowDark}>
                <div className={styles.fieldName}>
                    Status:
                </div>
                <div className={styles.fieldValue}>
                    {typeof props.subscription !== "undefined" ? props.subscription.status : "Scheduled For Renewal"}
                </div>
            </div>
        </div>
    )
}


export default React.memo(DetailsTable);