import React from 'react';
import styles from './PaymentPlans.module.css'; //styles
import Button from '../Button.js'; //styles


const PaymentPlans = (props) => {
    return(
            <div className={styles.paymentPlansContainer}>
               <div className={styles.paymentPlanContainer}>
                    <div className={styles.paymentPlanNamePrice}>
                        <div className={styles.planName}>
                            MONTHLY
                        </div>
                        <div className={styles.planPrice}>
                            <span className={styles.pricePound}>£</span> 800 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> month Equiv. </span>
                        </div>
                    </div>
                    <div className={styles.planDescription}>
                    You receive an invoice at the end of each month. Your first invoice will be due in a month.
                    </div>
                    <Button selected={props.paymentPlan === "monthly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "monthly")}  customStyle={{width: "80%"}} name={"Select Plan"}/>
               </div>
               <div className={styles.paymentPlanContainer}>
               <div className={styles.paymentPlanNamePrice}>
                        <div className={styles.planName}>
                            QUARTERLY
                        </div>
                        <div className={styles.planPrice}>
                            <span className={styles.pricePound}>£</span> 720 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> month Equiv. </span>
                        </div>
                    </div>
                    <div className={styles.planDescription}>
                    You receive an invoice at the end of each quarter. Your first invoice will be due in March.
                    </div>
                    <Button selected={props.paymentPlan === "quarterly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "quarterly")} customStyle={{width: "80%"}} name={"Select Plan"}/>
               </div>
               <div className={styles.paymentPlanContainer}>
               <div className={styles.paymentPlanNamePrice}>
                        <div className={styles.planName}>
                            YEARLY
                        </div>
                        <div className={styles.planPrice}>
                            <span className={styles.pricePound}>£</span> 640 <span className={styles.priceDash}> / </span><span className={styles.pricePerTime}> month Equiv. </span>
                        </div>
                    </div>
                    <div className={styles.planDescription}>
                    You make a one time upfront payment for the entire year.
                    </div>
                    <Button selected={props.paymentPlan === "yearly" ? true : false} nameSelected={"Selected"} onPress={() => props.handleChange("paymentPlan", "yearly")} customStyle={{width: "80%"}} name={"Select Plan"}/>
               </div>
            </div>
    )
}

PaymentPlans.defaultProps = {
    selected: ""
  };

export default React.memo(PaymentPlans);