import styles from './SendResetEmail.module.css'; //styles
import { useState, useCallback, useContext } from 'react';

import { UserContext } from "../../UserContext.js";


import TextInput from '../TextInput.js';
import Button from '../Button.js';
import helpers from '../../helpers.js';
import { json } from 'react-router-dom';


const SendResetEmail = (props) => {
    const {user, setUser} = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [prompt, setPrompt] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [highlighedtInput, setHighlighedtInput] = useState("");

    const handleSubmit = async () => {
        if(helpers.isValidEmail(email)){

            const request = {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  email: email
                }),
            };
        
            let response = await fetch(user.backEndURL + "/reset/email", request);
            let jsonResponse = await response.json();
            console.log(jsonResponse);
            if (jsonResponse.success) {
            console.log(jsonResponse)
            console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");        
            } else {
                console.log(jsonResponse);
                console.log("SILENTLY FAILS, LIKE A NINJA")
            }
            setEmailSent(true);
            setPrompt("You will shortly receive an email at " + email + " with instructions on how to reset your password");
        }else{
            helpers.errorPrompt("Please Enter Valid Email", "email", setPrompt, setHighlighedtInput)
        }
        return;
    };


    return(
        <div className={styles.container}>
            <h2 className={styles.header}>
                Forgot Your Password?
            </h2>
            <div className={styles.subHeader}>
            Enter the email associated with your account and we’ll send you an email with instructions to reset your password.
            </div>
            <div className={styles.inputWrapper}>
              <TextInput name={"Email"} value={email} onChange={setEmail} placeholder={"Your Email Address"} labelStyle={"top"} highlighted={highlighedtInput === "email"}/>
            </div>
            <div className={styles.buttonWrapper}>
                <Button name={"Submit"} color={"Grass"} onPress={handleSubmit} disabled={emailSent}/>
            </div>
            <Button name={"Back To Login"} color={"Coal"} onPress={props.returnToLogin}/>
            <div className={helpers.clx("errorPrompt", styles.prompt, emailSent ? styles.promptConfirmation : "")}>
                {prompt}
            </div>
        </div>
    )
}


export default SendResetEmail;