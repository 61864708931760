import { useContext, useState, useEffect } from 'react';
import { UserContext } from './UserContext.js';
import styles from './SubscriptionScreen.module.css';
import helpers from './helpers.js';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import Button from "./components/Button.js"
import DetailsTable from "./components/subscription/DetailsTable.js";
import Gauge from "./components/subscription/Gauge.js";
import ComingUp from "./components/subscription/ComingUp.js";


import iconCarbon from "./assets/images/iconCarbon.png";



function SubscriptionScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState({ //DEFAULT STATE
    status: "-",
    payment_plan: "",
    supervisorName: "",
    products: [{
      _product: "63f8ddff24c72d26e1aab989",
      amount_delivered: 0,
      amount_to_deliver: 0
    },
    {
      _product: "63f8def1ed95ea8a663ec536",
      amount_delivered: 0,
      amount_to_deliver: 0
    },
  ],
    amount_due: 0,
    amount_paid: 0
  });
  const [eventsComingUp, setEventsComingUp] = useState({ 
    
  });
  
  const [hasDeliveryDetails, setHasDeliveryDetails] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({ //DEFAULT STATE
    firstName: "-",
    lastName: "-",
    phone: "-",
    email: "-",
    businessName: "-",
    addressLine1: "-",
    addressLine2: "-",
    city: "-",
    zip: "-",
    county: "-",
    instructions: "-"
  });

  const [hasBillingDetails, setHasBillingDetails] = useState(false);
  const [billingDetails, setBillingDetails] = useState({ //DEFAULT STATE
    firstName: "-",
    lastName: "-",
    phone: "-",
    email: "-",
    businessName: "-",
    addressLine1: "-",
    addressLine2: "-",
    city: "-",
    zip: "-",
    county: "-"
  });


  useEffect(() => {
    console.log("Fetching Subscription")
    const fetchSubscription = async () => {

      let user = {};
      user.backEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
      user.token = localStorage.getItem("token");

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
        let response = await fetch(user.backEndURL + "/fetch-subscription", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data);

          setSubscription(jsonResponse.data.subscription);
          setSubscription(prevState => ({...prevState, supervisorName: jsonResponse.data.supervisorName}))
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          console.log("Fetch Subscription Failed...")
        }
      }
    }
    fetchSubscription();
  },[])

  useEffect(() => {
    console.log("Fetching Coming Up")
    const fetchComingUp = async () => {

      let user = {};
      user.backEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
      user.token = localStorage.getItem("token");

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
        let response = await fetch(user.backEndURL + "/coming-up", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data)
          let events = jsonResponse.data;
          for(let i = 0; i < (5 - jsonResponse.data.length); i++){
              events.push({type: "undefined"})
          }
          console.log(events);
          setEventsComingUp(events)
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A TURTULLEE");
        } else {
          console.log("Fetch COMING UP Failed...")
        }
      }
    }
    fetchComingUp();
  },[])

  useEffect(() => {
    console.log("Fetching Address Details")
    const fetchAddressDetails = async () => {

      let user = {};
      user.backEndURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : process.env.REACT_APP_API_BASE_URL;
      user.token = localStorage.getItem("token");

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
    
        let response = await fetch(user.backEndURL + "/fetch-address-details", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data)
          setDeliveryDetails({
            firstName: jsonResponse.data.deliveryDetails.first_name,
            lastName: jsonResponse.data.deliveryDetails.last_name,
            phone: jsonResponse.data.deliveryDetails.phone,
            email: jsonResponse.data.deliveryDetails.email,
            businessName: jsonResponse.data.deliveryDetails.business_name,
            addressLine1: jsonResponse.data.deliveryDetails.address_line_1,
            addressLine2: jsonResponse.data.deliveryDetails.address_line_2,
            city: jsonResponse.data.deliveryDetails.city,
            zip: jsonResponse.data.deliveryDetails.zip,
            county: jsonResponse.data.deliveryDetails.county,
            instructions: jsonResponse.data.deliveryDetails.instructions
          });
          setBillingDetails({
            firstName: jsonResponse.data.billingDetails.first_name,
            lastName: jsonResponse.data.billingDetails.last_name,
            phone: jsonResponse.data.billingDetails.phone,
            email: jsonResponse.data.billingDetails.email,
            businessName: jsonResponse.data.billingDetails.business_name,
            addressLine1: jsonResponse.data.billingDetails.address_line_1,
            addressLine2: jsonResponse.data.billingDetails.address_line_2,
            city: jsonResponse.data.billingDetails.city,
            zip: jsonResponse.data.billingDetails.zip,
            county: jsonResponse.data.billingDetails.county
          });
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          console.log("Fetch Address Details Failed...")
        }
      }
    }
    fetchAddressDetails();
  },[])

  const detailsValid = (details) => {
    if(details.email === "" || details.firstName === "" || details.zip === ""){
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Package"} content={"Set up your Black Bull Biochar package and start getting biochar delivered straight to your farm! Packages are made on a yearly basis. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module icon={"user"} name={"Package Details"} spanColumn={9} spanRow={1} height={"100%"}>
              <div className={styles.detailsContainer}>
                <DetailsTable user={user} subscription={subscription}/>
                <div className={styles.detailsButtonsRow}>
                  <Button disabled={detailsValid(deliveryDetails) && detailsValid(billingDetails) ? false : true} onPress={() => props.goToScreen("update-subscription", {addresses: {deliveryDetails: deliveryDetails, billingDetails: billingDetails}})} name={"Update Package"} color="Coal"/>
                </div>
              </div>
            </Module>
            <Module icon={iconCarbon} name={"Biochar"} spanColumn={6} spanRow={1} height={"100%"}>
              <div className={styles.gaugesContainer}>
                <Gauge progress={subscription.products.length === 0 ? 0 : subscription.products[0].amount_delivered} progressMax={subscription.products.length === 0 ? 0 : subscription.products[0].amount_to_deliver}/>
                <div className={styles.gaugeLabel}>
                  Pinchar Delivered
                </div>
                <div className={styles.gaugesSeparatorLine}/>
                <div style={{"opacity": 0.2, "flex-direction": "column"}}>
                  <Gauge progress={subscription.products.length === 0 ? 0 : subscription.products[1].amount_delivered} progressMax={subscription.products.length === 0 ? 0 : subscription.products[1].amount_to_deliver}/>
                    <div className={styles.gaugeLabel}>
                    Pelletchar Delivered
                  </div>
                </div>
                
              </div>
            </Module>
            <Module icon={"calendar-alt"} name={"Coming Up"} spanColumn={9} spanRow={1} height={"100%"}>
              <ComingUp events={eventsComingUp}/>  
            </Module>
            <Module icon={"location-arrow"} name={"Delivery Details"} spanColumn={12} height={"100%"}>
              <div className={styles.detailsWrapper}>
                <div className={styles.detailsRow}>
                  <div className={styles.detailsRowSection}>
                    <span className={styles.detailsRowSectionHeader}>Address</span>
                    {deliveryDetails.addressLine1}<br/>
                    {deliveryDetails.addressLine2}<br/>
                    {deliveryDetails.city}<br/>
                    {deliveryDetails.county}<br/>
                    {deliveryDetails.zip}
                  </div>
                  <div className={styles.detailsRowSection}>
                    <span className={styles.detailsRowSectionHeader}>Contact</span>
                    {deliveryDetails.firstName} {deliveryDetails.lastName}<br/>
                    {deliveryDetails.email}<br/>
                    {deliveryDetails.phone}<br/>
                  </div>
                </div>
                <div className={styles.detailsRow}>
                <div className={helpers.clx(styles.detailsRowSection, styles.detailsRowSectionFullWidth)}>
                    <span className={styles.detailsRowSectionHeader}>Delivery Instructions</span>
                    {deliveryDetails.instructions}
                    {/* When you reach the main road, turn left and continue for 3 miles until you see a large green barn on your right.
                    Turn right onto the dirt road directly after the barn.
                    Follow the dirt road for 1.5 miles until you reach a split in the road.
                    Take the right fork and continue for 1 mile until you see a red farmhouse on your left.
                    Turn left onto the gravel driveway just before the farmhouse and continue to the back of the property.
                    The delivery location is the large metal storage shed behind the farmhouse.
                    Please be aware that the driveway and roads leading to the farm are unpaved and may be difficult to navigate for larger vehicles.
                    If you have any trouble finding the location, please call the contact provided for further assistance. */}
                  </div>
                </div>
                <Button onPress={() => props.goToScreen("update-delivery-details", {deliveryDetails: deliveryDetails})} name={"Update Delivery Details"} color="Coal"/>
              </div>
            </Module>
            <Module icon={"money-bill"} name={"Billing Details"} spanColumn={12} height={"100%"}>
            <div className={styles.detailsWrapper}>
                <div className={styles.detailsRow}>
                  <div className={styles.detailsRowSection}>
                    <span className={styles.detailsRowSectionHeader}>Address</span>
                    {billingDetails.addressLine1}<br/>
                    {billingDetails.addressLine2}<br/>
                    {billingDetails.city}<br/>
                    {billingDetails.county}<br/>
                    {billingDetails.zip}
                  </div>
                  <div className={styles.detailsRowSection}>
                    <span className={styles.detailsRowSectionHeader}>Contact</span>
                    {billingDetails.firstName} {billingDetails.lastName}<br/>
                    {billingDetails.email}<br/>
                    {billingDetails.phone}<br/>
                  </div>
                </div>
                <Button onPress={() => props.goToScreen("update-billing-details", {billingDetails: billingDetails})} name={"Update Billing Details"} color="Coal"/>
              </div>
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionScreen;
