import styles from './Module.module.css'; //styles
import helpers from '../helpers.js';
import { useCallback } from 'react';


/*
    Module
        props:
            spanRow: grid row height (1 = 100px)
            spanColumn: grid column width (/24 eg: 6 takes up a quarter of the screen) 
*/
const Module = (props) => {
    const renderIcon = useCallback(() => {
        if(props.icon === ""){
            return;
        } else if(props.icon.length > 20){ //image url since too long for FontAwesome
            return <img className={styles.headerIcon} src={props.icon}/>
        } else {
            return <span className={styles.headerIcon}>{props.icon}</span>
        }
    }, [props.icon])

    return(
        <div className={styles.moduleContainer} style={{gridRow: "span " + props.spanRow, gridColumn: "span " + props.spanColumn, height: props.height, background: props.background}}>
            <h3 className={styles.header}>
                {renderIcon()}
                {props.name}
            </h3>
            {props.name !== "" ? <div className={styles.line}/> : null }
            {props.children}
        </div>
    )
}

Module.defaultProps = {
    height: "100%",
    background: "",
    icon: "",
    name: ""
  };

export default Module;