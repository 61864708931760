import styles from './CharcodeTooltip.module.css'; //styles
import helpers from '../helpers.js';

import Charcode from "./Charcode.js";






/*
    STANDARD TEXT INPUT
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onChange: setter from parent
            height: sets height, and turns it into textarea
            customStyle: TODO based on future requirements
*/
const CharcodeTooltip = (props) => {

   
    
  
    let centerX = window.innerWidth/2;
    let centerY = window.innerHeight/2;
    let stringY = props.position.y > centerY ? "top" : "bottom";
    let stringX = props.position.x > centerX ? "Left" : "Right";
    let direction = stringY + stringX;
    console.log(stringY);
    let arrowClass = "";
    let bubbleClass = "";
    switch(direction){
        case "topLeft":
            bubbleClass = styles.topLeft;
            arrowClass = styles.arrowTopLeft;
            break;
        case "topRight":
            bubbleClass = styles.topRight;
            arrowClass = styles.arrowTopRight;
            break;
        case "bottomLeft":
            bubbleClass = styles.bottomLeft;
            arrowClass = styles.arrowBottomLeft;
            break;
        case "bottomRight":
            bubbleClass = styles.bottomRight;
            arrowClass = styles.arrowBottomRight;
            break;
    }

    
    return(
        <>
            <span className={helpers.clx(styles.tooltip)}>
                <div className={styles.hoverArea}/>
                <div style={{minWidth: props.minWidth, maxWidth: props.maxWidth}} className={helpers.clx(styles.bubble, bubbleClass, !props.displayTip ? styles.bubbleHidden : "")}>
                    <div className={arrowClass}/>
                    <div className={styles.container}>
                        <div className={styles.columnLeft}>
                          <div className={styles.farmerRow}>{props.bagInfo.farmer}</div>
                          <div className={styles.status}>{props.bagInfo.status}</div>
                          <Charcode charcodeId={props.charcodeId} isTooltip={true}/>
                        </div>
                        <div className={styles.columnRight}>
                          <div className={helpers.clx(styles.row, styles.rowFull)}>
                            <div className={styles.fieldName}>
                                Batch ID:
                            </div>
                            <div>
                                {props.bagInfo.batchId}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.fieldName}>
                                Site of Origin:
                            </div>
                            <div>
                                {props.bagInfo.site}
                            </div>
                          </div>
                          <div className={helpers.clx(styles.row, styles.rowFull)}>
                            <div className={styles.fieldName}>
                                Production Date:
                            </div>
                            <div>
                                {props.bagInfo.productionDate}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.fieldName}>
                                Delivery Date:
                            </div>
                            <div>
                                {props.bagInfo.deliveryDate}
                            </div>
                          </div>
                          <div className={helpers.clx(styles.row, styles.rowFull)}>
                            <div className={styles.fieldName}>
                                Delivery ID:
                            </div>
                            <div>
                                {props.bagInfo.deliveryId}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.fieldName}>
                                Order ID:
                            </div>
                            <div>
                                {props.bagInfo.orderId}
                            </div>
                          </div>
                          <div className={helpers.clx(styles.row, styles.rowFull)}>
                            <div className={styles.fieldName}>
                                Applied To:
                            </div>
                            <div>
                                {props.bagInfo.appliedTo}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.fieldName}>
                                Application Date:
                            </div>
                            <div>
                                {props.bagInfo.applicationDate}
                            </div>
                          </div>
                        </div>
                    </div>

                </div>
            </span>
        </>
    )
}

CharcodeTooltip.defaultProps = {
    bagInfo: {
        farmer: "John Reaves",
        status: "Delivered",
        id: "CHA-002528",
        batchId: "323231",
        site: "ARA",
        productionDate: "13/02/2024",
        deliveryDate: "11/02/2024",
        deliveryId: "DEL-00231",
        orderId: "ORD-00142",
        appliedTo: "Slurry",
        applicationDate: "11/04/2024"
    }
  };

export default CharcodeTooltip;