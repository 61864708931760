import { useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";

import SendResetEmail from "./components/forgotPassword/SendResetEmail.js";
import ResetPassword from "./components/forgotPassword/ResetPassword.js";


import helpers from "./helpers.js";
import styles from './ForgotPasswordScreen.module.css'; //styles

import greenCircles from "./assets/images/greenCirclesCorner.png";
import bbbLogo from "./assets/images/bbbLogo.png";





function ForgotPasswordScreen() {
  const [transitioningInto, setTransitioningInto] = useState(0);
  const [transitioningFrom, setTransitioningFrom] = useState(0);

  const [screen, setScreen] = useState(() => {
    console.log(window.location.search);
    if (window.location.search.includes("?token")){
      console.log("HERE")
      return "resetPassword";
    }else{
      console.log("THERE")
      return "sendResetEmail";
    }
  });

  const [transitioning, setTransitioning] = useState(true);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setTimeout(() => setTransitioning(false) , 200)
  }, [])
  

  const navigate = useNavigate();
 
  const returnToLogin = () => {
    navigate("/login");
  }
  
  
  const renderContent = () => {
    switch(screen){
      case "sendResetEmail": return<SendResetEmail returnToLogin={returnToLogin}/>;
      case "resetPassword": return<ResetPassword returnToLogin={returnToLogin}/>;
    }
  }

  
  return (
    <div className={'mainBlackContainer'}>
      <div className={styles.blackContainerContent}>
        
      </div>
      <div className={helpers.clx("mainWhiteContainer", styles.mainWhiteContainer)}>
        <div className={styles.logoContainer}>
          <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
        </div>
        <div className={styles.greenCirclesWrapper}>
          <img src={greenCircles} className={helpers.clx(styles.greenCircle1, transitioning ? styles.greenCircle1Hidden : "")} alt="Green Circle"/>
          <img src={greenCircles} className={helpers.clx(styles.greenCircle2, transitioning ? styles.greenCircle2Hidden : "")} alt="Green Circle"/>
        </div>
          {renderContent()}
      </div>
    </div>
  );
}




export default React.memo(ForgotPasswordScreen);
