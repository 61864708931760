import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './SubscriptionDetails.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';

import DetailsTable from "../../components/subscription/DetailsTable.js";


import TextInput from "../../components/TextInput.js";
import Button from "../../components/Button.js";
import Module from "../../components/Module.js";
import Gauge from "../../components/subscription/Gauge.js";
import Overlay from "../../components/Overlay.js";
import Selector from "../../components/Selector.js";
import ProductCounter from "../../components/subscription/ProductCounter.js";
import countries from "../../assets/Countries.js";


function SubscriptionDetails(props) {
  const [progressCount, setProgressCount] = useState(0);

  

  return (
    <div className={helpers.clx("contentGrid", styles.contentGridNoBg)}>
      <Module icon={"user"} name={"Subscriptions Details"} spanColumn={24} height={"100%"}>
        <div className={styles.subscriptionDetailsContainer}>
          <div className={styles.leftColumn}>
            <DetailsTable user={props.user} subscription={props.subscription}/>
          </div>
          <div className={styles.rightColumn}>
            <Gauge progress={30} progressMax={80}/>
            <div>
              Pinchar Delivered
            </div>
            <div className={styles.gaugeSeparatorLine}/>
            <Gauge progress={50} progressMax={210}/>
            <div>
              Pelletchar Delivered
            </div>
          </div>
        </div>
      </Module>
    </div>
  );
}

export default SubscriptionDetails;
