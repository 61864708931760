import { useNavigate } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";

import helpers from "./helpers.js";
import styles from './SignUpScreen.module.css'; //styles

import OnboardingProgressBar from "./components/signUp/OnboardingProgressBar.js";

import ReferralContent from "./components/signUp/ReferralContent.js";
import DetailsContent from "./components/signUp/DetailsContent.js";
import QuestionnaireContent from "./components/signUp/QuestionnaireContent.js";
import ConfirmationContent from "./components/signUp/ConfirmationContent.js";
import SuccessContent from "./components/signUp/SuccessContent.js";



import bbbLogoWhite from "./assets/images/bbbLogoWhiteTransparentNoText.png";


function SignUpScreen() {
  const [step, setStep] = useState(0);
  const [transitioningInto, setTransitioningInto] = useState(0);
  const [transitioningFrom, setTransitioningFrom] = useState(0);

  const [transitioning, setTransitioning] = useState(true);
  const [formData, setFormData] = useState({
    referralCode: "",
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    businessName: "",
    country: "",
    homePhone: "",
    mobilePhone: "",
    subscribeToNewsletter: false,
    termsConditions: false,
    isOrganic: false,
    isConventional: false,
    questionnaireData: [
      {questionName: "Farm Activities", tags: ["Cereals", "General cropping", "Dairy", "Grazing livestock (Lowland)", "Grazing livestock (LFA)", "Pigs", "Poultry", "Horticulutre"], selectedTags: []},
      {questionName: "Priorities", tags: ["Bedding conditions", "Improved organic fertilizer", "Environmental protection", "Crop yield", "Soil water", "Soil yield"], selectedTags: []}
    ]
  });

  useEffect(() => {
    setTimeout(() => setTransitioning(false) , 200)
  }, [])
  
  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };

  const updateQuestionnaireData = (questionIndex, tag) => {
    console.log("HEEEEEEEE")

    let questionnaireData = formData.questionnaireData;
 
    if(questionnaireData[questionIndex].selectedTags.includes(tag)){
      console.log("includes")
      questionnaireData[questionIndex].selectedTags = questionnaireData[questionIndex].selectedTags.filter(e => e !== tag); 
    } else {
      console.log("doesnt includes")

      questionnaireData[questionIndex].selectedTags.push(tag);
    }
    console.log(questionnaireData);
    setFormData({ ...formData, questionnaireData: questionnaireData })
  }

  const navigate = useNavigate(); //use callback
  const nextStep = useCallback(() => {
    if(transitioning){
      return;
    }else if(step === 4){
      navigate("/login")
    } else {
      setTransitioningInto(step + 1)
      setTransitioningFrom(step)        
      setTransitioning(true);
      setTimeout(() => {
        setStep((currentStep) => {return currentStep + 1});
        setTimeout(() => {
          setTransitioning(false);  
        }, 100)
      }, 400)
    }
  }, [transitioning]);

  const prevStep = useCallback(() => {
    if(transitioning){
      return;
    } else {
      setTransitioningInto(step - 1)
      setTransitioningFrom(step)        
      setTransitioning(true);
      setTimeout(() => {
        setStep((currentStep) => {return currentStep - 1});
        setTimeout(() => {
          setTransitioning(false);          
        }, 100)
      }, 400)
    }
  }, [transitioning]);

  const renderContent = () => {
    switch(step){
      case 0: return <ReferralContent formData={formData} handleChange={handleChange} transitioning={transitioning} nextStep={nextStep}/>
      case 1: return <DetailsContent formData={formData} handleChange={handleChange} transitioning={transitioning} nextStep={nextStep} prevStep={prevStep} transitioningFrom={transitioningFrom} transitioningInto={transitioningInto}/>
      case 2: return <QuestionnaireContent formData={formData} handleChange={handleChange} transitioning={transitioning} nextStep={nextStep} prevStep={prevStep} transitioningFrom={transitioningFrom} transitioningInto={transitioningInto} updateQuestionnaireData={updateQuestionnaireData}/>
      case 3: return <ConfirmationContent formData={formData} handleChange={handleChange} transitioning={transitioning} nextStep={nextStep} prevStep={prevStep} transitioningFrom={transitioningFrom} transitioningInto={transitioningInto}/>
      case 4: return <SuccessContent formData={formData} handleChange={handleChange} transitioning={transitioning} nextStep={nextStep} prevStep={prevStep} transitioningFrom={transitioningFrom} transitioningInto={transitioningInto}/>
    }
  }
  
  return (
    <div className={'mainBlackContainer'}>
      <div className={styles.blackContainerContent}>
        <div className={styles.bbbLogoWhiteContainer}>
          <img src={bbbLogoWhite} className={styles.bbbLogoWhite}/>
          <div className={styles.bbbLogoText}>
            BLACK BULL BIOCHAR
          </div>
        </div>
        <OnboardingProgressBar step={step}/>
      </div>
      <div className={helpers.clx("mainWhiteContainer", styles.mainWhiteContainer)}>
        {renderContent()}
      </div>
    </div>
  );
}




export default React.memo(SignUpScreen);
