import React, {useMemo, useState, useContext} from 'react';
import { UserContext } from "../../UserContext.js";

import styles from './ConfirmationContent.module.css'; //styles
import helpers from '../../helpers.js';

import Button from "../Button.js";
import Checkbox from "../Checkbox.js";


import bbbLogo from "../../assets/images/bbbLogo.png";
import countries from "../../assets/Countries.js";

const ConfirmationContent = (props) => {
    const {user, setUser} = useContext(UserContext);
    const [errorPrompt, setErrorPrompt] = useState("");


    const handleSubmit = async () => {
        if(!props.formData.termsConditions){
            helpers.errorPrompt("Please accept our terms and conditions to proceed", "", setErrorPrompt, null)
            return;
        }
        const request = {
            method: "put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(props.formData),
          };
      
          let response = await fetch(user.backEndURL + "/sign-up", request);
          let jsonResponse = await response.json();
          if (jsonResponse.success) {
            console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");      
            props.nextStep();
            return;
          } else {
            helpers.errorPrompt("Oops, something went wrong ... ", "", setErrorPrompt, null)
            console.log("SILENTLY FAILS, LIKE A NINJA")
            return;
          }  
    }

    return(
        <> 
            <div className={helpers.clx(styles.logoContainer, props.transitioning && props.transitioningInto === 4 ? styles.logoContainerGrow : "")}>
                <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
            </div>
            <div className={helpers.clx(styles.confirmationContent, props.transitioning ? styles.confirmationContentHidden : "")}>
                <h2 className={styles.confirmationHeader}>
                    You're Almost There !
                </h2>
                <p className={styles.confirmationText}>To complete your registration, please read and accept our terms and conditions. You may unsubscribe from our newsletter at any time.</p>
                <div className={styles.checkboxRow}>
                    <Checkbox checked={props.formData.subscribeToNewsletter} onPress={() => props.handleChange("subscribeToNewsletter", !props.formData.subscribeToNewsletter)} text={"I would like to subscribe to the BBB Newsletter"}/>                   
                    <Checkbox checked={props.formData.termsConditions} onPress={() => props.handleChange("termsConditions", !props.formData.termsConditions)} text={<div>I have read and accept the&nbsp;<a href={"https://blackbullbiochar.com/data-policy-app"} target="_blank" rel="noopener noreferrer" className={styles.hyperlink}>terms and conditions</a></div>}/>                   
                </div>
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={handleSubmit} name="Create Account" color="Grass" customStyle={{margin: "0.5rem"}}/>
                    <Button disabled={false} onPress={props.prevStep} name="Back" color="Error"/>
                </div>
                <div className={helpers.clx("errorPrompt", styles.errorPromptContainer)}>
                    {errorPrompt}
                </div>
            </div>
        </>
    )
}


export default React.memo(ConfirmationContent);