import styles from './Incrementer.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';



/*
    CHECKBOX
        props:
            checked: boolean, sets checkbox status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before checkbox 
*/
const Incrementer = (props) => {
    // const [value, setValue] = useState(props.value);


    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    return(
        <div className={styles.container}>     
            <div className={styles.label}>
                Number Of Bags
            </div>          
            <div className={styles.incrementerContainer}>
            <div onClick={() => props.handleChange( props.fieldName, (props.value - props.increment) < props.minValue ? props.minValue : (props.value - props.increment))}  className={helpers.clx(styles.button, styles.minusButton )}>
                -
            </div>
            <input type="text" value={props.value} onChange={(e) => props.handleChange( props.fieldName,  e.target.value.length === 0 ? 0 : parseInt(e.target.value))} className={styles.valueInput}>
                
            </input>
            <div onClick={() => props.handleChange( props.fieldName, (props.value + props.increment) < props.minValue ? props.minValue : (props.value + props.increment))} className={helpers.clx(styles.button, styles.plusButton )}>
                +
            </div>
            </div>
        </div>
    )
}

Incrementer.defaultProps = {
    minValue: 0,
    maxValue: 999,
    increment: 1
  };

export default Incrementer;