
import {useState, useEffect, useContext} from "react";
import { UserContext } from '../../UserContext.js';

import styles from './UserDetails.module.css';
import helpers from '../../helpers.js';

import TextInput from "../TextInput.js";
import Button from "../Button.js";
import Selector from "../Selector.js";


import countries from "../../assets/Countries.js";







function UserDetails(props) {
  const {user, setUser} = useContext(UserContext);

  const [errorPrompt, setErrorPrompt] = useState("");
  const [highlighedtInput, setHighlighedtInput] = useState("");
  const [updated, setUpdated] = useState(false);
  const [defaultUserDetails, setDefaultUserDetails] = useState({
    email: "john.doe@gmail.com",
    firstName: "John",
    lastName: "Doe",
    businessName: "John's Dairy Farm",
    country: "GB",
    homePhone: "+44 74 458 42238",              
    mobilePhone: "+44 34 521 12038"
  });
  const [userDetails, setUserDetails] = useState({
    email: "john.doe@gmail.com",
    firstName: "John",
    lastName: "Doe",
    businessName: "John's Dairy Farm",
    country: "GB",
    homePhone: "+44 74 458 42238",              
    mobilePhone: "+44 34 521 12038"
  });

  const handleChange = (formField, value) => {
    setUserDetails({ ...userDetails, [formField]: value });
    setUpdated(true);
  };

  const resetUserDetails = () => {
    setUserDetails(defaultUserDetails);
    setUpdated(false);
  }

  const handleSubmit = async () => {
    props.updateSettings(userDetails, null, null);
    setUpdated(false);
  };

  useEffect(() => {
    console.log("Fetching User Details")
    const fetchUserDetails = async () => {

      if (user.token === null){
        console.log("NO TOKEN")
        user.authed = false;
      } else {
        const request = {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          }
        };
        let response = await fetch(user.backEndURL + "/fetch-user-details", request);
        let jsonResponse = await response.json();
        if (jsonResponse.success) {
          console.log(jsonResponse.data);

          setUserDetails(jsonResponse.data);
          console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
        } else {
          console.log("Fetch User Details Failed...")
        }
      }
    }
    fetchUserDetails();
  },[])

  return (
    <div className={styles.userDetailsContainer}>
        <div className={styles.inputRow}>
            <TextInput name={"E-Mail"} value={userDetails.email} onChange={(value) => handleChange("email", value)} highlighted={highlighedtInput === "email" ? true : false} labelStyle={"top"} placeholder={"Enter E-Mail"}/>
        </div>
        <div className={styles.inputRow}>
            <TextInput name={"First Name"} value={userDetails.firstName} onChange={(value) => handleChange("firstName", value)} highlighted={highlighedtInput === "firstName" ? true : false} labelStyle={"top"} placeholder={"Enter First Name"}/>
        </div>
        <div className={styles.inputRow}>
            <TextInput name={"Last Name"} value={userDetails.lastName} onChange={(value) => handleChange("lastName", value)} highlighted={highlighedtInput === "lastName" ? true : false} labelStyle={"top"} placeholder={"Enter Last Name"}/>
        </div>
        <div className={styles.inputRow}>
            <TextInput name={"Business Name"} value={userDetails.businessName} onChange={(value) => handleChange("businessName", value)} highlighted={highlighedtInput === "businessName" ? true : false} labelStyle={"top"} placeholder={"Enter Business Name"}/>
        </div>
        <div className={styles.inputRow}>
           <Selector name="Country" value={userDetails.country} data={countries} onChange={(value) => handleChange("country", value)} highlighted={highlighedtInput === "country" ? true : false} labelStyle="top" placeholder="Select Country" color="white"/>
        </div>
        <div className={styles.inputRow}>
            <TextInput name={"Home Phone"} value={userDetails.homePhone} onChange={(value) => handleChange("homePhone", value)} highlighted={highlighedtInput === "homePhone" ? true : false} labelStyle={"top"} placeholder={"Enter Home Phone"}/>
        </div>
        <div className={styles.inputRow}>
            <TextInput name={"Mobile Phone"} value={userDetails.mobilePhone} onChange={(value) => handleChange("mobilePhone", value)} highlighted={highlighedtInput === "mobilePhone" ? true : false} labelStyle={"top"} placeholder={"Enter Mobile Phone"}/>
        </div>
        <div className={styles.buttonRow}>
            <Button disabled={updated ? false : true} onPress={() => handleSubmit()} name={"Update"} color={"Grass"}/>
            <Button disabled={updated ? false : true} onPress={() => resetUserDetails()} name={"Cancel"} color={"Error"}/>
        </div>
    </div>         
  );
}

export default UserDetails;
