
import styles from './HelpCenterScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../UserContext.js';

import DetailsTable from "../components/subscription/DetailsTable.js";


import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import Overlay from "../components/Overlay.js";



function HelpCenterScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [supportTickets, setSupportTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  


  const fetchSupportTickets = async () => {
    const request = {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    let response = await fetch(user.backEndURL + "/support-tickets", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      setSupportTickets(jsonResponse.data);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  const resolveSupportTicket = async () => {
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: selectedTicket._id,
        status: "resolved",
        priority: "low"
      }),
    };
    let response = await fetch(user.backEndURL + "/support-ticket", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      fetchSupportTickets();
      setSelectedTicket(null);
      console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    } else {
      alert("Something Went Wrong...")
    }
  }
  

  useEffect(() => {
    console.log("Fetching Farmers")
   
    fetchSupportTickets();
  },[user])


  const renderTicketOverlay = () => {
    if(selectedTicket === null){
      return;
    }
    let d = new Date(selectedTicket.created_at);
    return(
      <Overlay>
        <div className={styles.ticketContainer}>
          <h2>Support Ticket</h2>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Status:</div>{selectedTicket.status !== "resolved" ? <div className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Unresolved</div> : <div className={styles.statusResolved}><span className={styles.statusIcon}>check-circle</span>Resolved</div>}
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>ID:</div>  <div>{selectedTicket._id}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>User:</div><div> {selectedTicket._user.first_name + " " + selectedTicket._user.last_name}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Email:</div><div>{selectedTicket._user.email}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Mobile Phone:</div><div>{selectedTicket._user.phone_mobile}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Home Phone:</div><div>{selectedTicket._user.phone_home}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Created On:</div><div>{d.getDate() + "/" + (d.getMonth() + 1)+ "/" + d.getFullYear()}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Subject:</div><div>{selectedTicket.subject}</div>
          </div>
          <div className={styles.ticketOverlayRow}>
            <div className={styles.ticketOverlayLabel}>Message:</div><div>{selectedTicket.message}</div>
          </div>
          <Button name={"Resolve Ticket"} onPress={() => resolveSupportTicket()} disabled={selectedTicket.status === "resolved" ? true : false}/>
          <Button name={"Back"} color={"Error"} onPress={() => setSelectedTicket(null)}/>
        </div>
      </Overlay>
    )
  }

  const updateFarmerList = () => {
    fetchSupportTickets();
  }

  const renderSupportTickets = () => {
    let filteredSupportTickets = [];
    if(selectedFilter === "all"){
      filteredSupportTickets = supportTickets;
    }else {
      filteredSupportTickets = supportTickets.filter(function (supportTicket) {
        return supportTicket.status === selectedFilter 
      }
    );
  }

  const renderStatus = (status) => {
    switch(status){
      case "all":
        return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Paid</td>
      case "unresolved":
        return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Unresolved</td>
      case "resolved":
        return <td className={styles.statusResolved}><span className={styles.statusIcon}>check-circle</span>Resolved</td>
      default:
          return <td>N/A</td>
    }

  }
 

  return(
    <>
      {filteredSupportTickets.map(supportTicket => {
        let d = new Date(supportTicket.created_at);
        return(
          <>
            <tr className={styles.tableRow} onClick={() => setSelectedTicket(supportTicket)}>
              {renderStatus(supportTicket.status)}
              <td>{supportTicket._user.first_name + " " + supportTicket._user.last_name}</td>
              <td><span className={styles.dataBold}>{supportTicket.email} </span></td>
              
              <td className={styles.downloadInvoice}>{supportTicket.subject}</td>
              <td className={styles.downloadInvoice}>{d.getDate() + "/" + (d.getMonth() + 1)+ "/" + d.getFullYear()}</td>
            </tr>
            
          </>
          ) 
        })
      }
      </>
    )
    }

  // const renderFarmerContent = () => {
  //   switch(selectedTab){
  //     case "Details":
  //       return <div> DETAILS </div>
  //     case "Subscription":
  //       let farmer = {details:{
  //         businessName: farmers[selectedFarmerIndex].business_name
  //       }}
  //       let subscription = {
  //         supervisorName: "Arla",
  //         payment_plan: farmers[selectedFarmerIndex].subscription.payment_plan
  //       }
  //       return <DetailsTable user={farmer} subscription={subscription}/>
  //     case "Deliveries":
  //       return <Deliveries  updateFarmerList={updateFarmerList} farmer={farmers[selectedFarmerIndex]}/>
  //     default:
  //         return <td>FEATURE COMING UP</td>
  //   }

  // }//

  

  // const renderFarmerInvite = () => {
  //   console.log("HEREInvite")
  //   if(displayInvite){
  //     return(
  //       <Overlay>
  //       <div className={styles.farmerInviteContainer}>
  //         <h2>Invite New Farmer</h2>
  //         <Selector  labelStyle={"top"} name={"Supervisor"} value={"6513c72dafb464a76c57713e"} data={[{value: "6513c72dafb464a76c57713e", name: "Arla"}]}/>
  //         <TextInput value={emailFarmerInvite} onChange={setEmailFarmerInvite} labelStyle={"top"} name={"Email"} placeholder={"farmer@email.com"}/>
  //         <Button name={"Send Invite"} onPress={inviteFarmer}/>
  //         <Button name={"Cancel"} color={"Error"} onPress={() => setDisplayInvite(false)}/>
  //       </div>
  //     </Overlay>
  //     )
  //   }else{
  //     return null;
  //   }
  // }
  
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/help-center"}/>

      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Help Center"} content={"Manage support tickets and post notifications"}/>
          <div className={"contentGrid"}>
            <Module spanColumn={24} spanRow={3} height={"580px"} icon={"ticket-alt"} name={"Support Tickets"}>
            {renderTicketOverlay()}
            <div className={styles.headerRow}>
                <div onClick={() => setSelectedFilter("all")} className={helpers.clx(styles.headerFilter, selectedFilter === "all" ? styles.headerFilterSelected : "")}>
                  All
                </div>
                <div onClick={() => setSelectedFilter("unresolved")} className={helpers.clx(styles.headerFilter, selectedFilter === "unresolved" ? styles.headerFilterSelected : "")}>
                  Unresolved
                </div>
                <div onClick={() => setSelectedFilter("resolved")} className={helpers.clx(styles.headerFilter, selectedFilter === "resolved" ? styles.headerFilterSelected : "")}>
                  Resolved
                </div>
              </div>
              <div className={styles.farmersTableWrapper}>
                <table className={styles.farmersTable}>
                  <tr className={styles.farmersHeaderRow}>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Subject</th>
                    <th>Date</th>
                  </tr>
                  {renderSupportTickets()}
                </table>
              </div>  
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}
//

export default HelpCenterScreen;
