
import styles from './HomeScreen.module.css';
import helpers from './helpers.js';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";



function HomeScreen(props) {
  console.log(helpers.clx(styles.MainBlack, "green"));
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"home"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Home"} content={"Welcome back John Doe! Here's a quick glimpse at your latest biochar related activities and stats"}/>
          <div className={"contentGrid"}>
            <Module name={"Welcome Back!"} spanColumn={24} spanRow={1} height={"auto"}>
              Module 1 
              Module 2
              <div style={{height:'200px'}}>
                sdsd
              </div>
            </Module>
            <Module name={"Random Module"} spanColumn={24} spanRow={1} height={"100px"}>
              Module 1
            </Module>
            <Module spanColumn={12} spanRow={1} height={"100%"}>
              selectedTrapezium
              SD
            
            </Module>
            
            <Module spanColumn={12} spanRow={2} height={"40vw"}>
              selectedTrapezium
              SD
            
            </Module>
            <Module spanColumn={12} spanRow={1} height={"100%"}>
              selectedTrapezium
              SD
            
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
