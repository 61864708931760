import React, {useMemo, useState} from 'react';
import styles from './OnboardingProgressBar.module.css'; //styles
import helpers from '../../helpers.js';



/*
    
        props:
            name: 
*/
const OnBoardingProgressBar = (props) => {

    let colorString = "Container" + props.color;
    console.log("render")

    const progressClasses = useMemo(() => {
        let classes = {};
        switch(props.step){
            case 0: 
                classes.bigCircle0 = "bigCircleActive";
                classes.bigCircle1 = "";
                classes.bigCircle2 = "";
                classes.bigCircle3 = "";
                classes.smallCircle0 = "smallCircleActive";
                classes.smallCircle1 = "";
                classes.smallCircle2 = "";
                classes.smallCircle3 = "";
                classes.progressStepName0 = "progressStepNameActive";
                classes.progressStepName1 = "";
                classes.progressStepName2 = "";
                classes.progressStepName3 = "";
                classes.dots1 = "";
                classes.dots2 = "";
                classes.dots3 = "";
                break;
            case 1: 
                classes.bigCircle0 = "bigCircleComplete";
                classes.bigCircle1 = "bigCircleActive";
                classes.bigCircle2 = "";
                classes.bigCircle3 = "";
                classes.smallCircle0 = "smallCircleComplete";
                classes.smallCircle1 = "smallCircleActive";
                classes.smallCircle2 = "";
                classes.smallCircle3 = "";
                classes.progressStepName0 = "progressStepNameComplete";
                classes.progressStepName1 = "progressStepNameActive";
                classes.progressStepName2 = "";
                classes.progressStepName3 = "";
                classes.dots1 = "dotsComplete";
                classes.dots2 = "";
                classes.dots3 = "";
                break;
            case 2: 
                classes.bigCircle0 = "bigCircleComplete";
                classes.bigCircle1 = "bigCircleComplete";
                classes.bigCircle2 = "bigCircleActive";
                classes.bigCircle3 = "";
                classes.smallCircle0 = "smallCircleComplete";
                classes.smallCircle1 = "smallCircleComplete";
                classes.smallCircle2 = "smallCircleActive";
                classes.smallCircle3 = "";
                classes.progressStepName0 = "progressStepNameComplete";
                classes.progressStepName1 = "progressStepNameComplete";
                classes.progressStepName2 = "progressStepNameActive";
                classes.progressStepName3 = "";
                classes.dots1 = "dotsComplete";
                classes.dots2 = "dotsComplete";
                classes.dots3 = "";
                break;
            case 3: 
                classes.bigCircle0 = "bigCircleComplete";
                classes.bigCircle1 = "bigCircleComplete";
                classes.bigCircle2 = "bigCircleComplete";
                classes.bigCircle3 = "bigCircleActive";
                classes.smallCircle0 = "smallCircleComplete";
                classes.smallCircle1 = "smallCircleComplete";
                classes.smallCircle2 = "smallCircleComplete";
                classes.smallCircle3 = "smallCircleActive";
                classes.progressStepName0 = "progressStepNameComplete";
                classes.progressStepName1 = "progressStepNameComplete";
                classes.progressStepName2 = "progressStepNameComplete";
                classes.progressStepName3 = "progressStepNameActive";
                classes.dots1 = "dotsComplete";
                classes.dots2 = "dotsComplete";
                classes.dots3 = "dotsComplete";
                break;
            case 4: 
                classes.bigCircle0 = "bigCircleComplete";
                classes.bigCircle1 = "bigCircleComplete";
                classes.bigCircle2 = "bigCircleComplete";
                classes.bigCircle3 = "bigCircleComplete";
                classes.smallCircle0 = "smallCircleComplete";
                classes.smallCircle1 = "smallCircleComplete";
                classes.smallCircle2 = "smallCircleComplete";
                classes.smallCircle3 = "smallCircleComplete";
                classes.progressStepName0 = "progressStepNameComplete";
                classes.progressStepName1 = "progressStepNameComplete";
                classes.progressStepName2 = "progressStepNameComplete";
                classes.progressStepName3 = "progressStepNameComplete";
                classes.dots1 = "dotsComplete";
                classes.dots2 = "dotsComplete";
                classes.dots3 = "dotsComplete";
                break;
            default: return;
        }
        return classes;
    }, [props.step])

    return(
            <div className={styles.mainContainer}>
                <div className={styles.row}>
                    <div className={helpers.clx(styles.bigCircle, styles[progressClasses.bigCircle0])}>
                        <div className={helpers.clx(styles.smallCircle, styles[progressClasses.smallCircle0])}/>
                    </div>
                    <div className={helpers.clx(styles.progressStepName, styles[progressClasses.progressStepName0])}>
                        Validate Referral
                    </div>
                </div>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots1])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots1])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots1])}/>
                <div className={styles.row}>
                    <div className={helpers.clx(styles.bigCircle, styles[progressClasses.bigCircle1])}>
                        <div className={helpers.clx(styles.smallCircle, styles[progressClasses.smallCircle1])}/>
                    </div>
                    <div className={helpers.clx(styles.progressStepName, styles[progressClasses.progressStepName1])}>
                        Enter Personal Details
                    </div>
                </div>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots2])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots2])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots2])}/>
                <div className={styles.row}>
                    <div className={helpers.clx(styles.bigCircle, styles[progressClasses.bigCircle2])}>
                        <div className={helpers.clx(styles.smallCircle, styles[progressClasses.smallCircle2])}/>
                    </div>
                    <div className={helpers.clx(styles.progressStepName, styles[progressClasses.progressStepName2])}>
                        Tell Us More About Yourself
                    </div>
                </div>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots3])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots3])}/>
                <div className={helpers.clx(styles.dots, styles[progressClasses.dots3])}/>
                <div className={styles.row}>
                    <div className={helpers.clx(styles.bigCircle, styles[progressClasses.bigCircle3])}>
                        <div className={helpers.clx(styles.smallCircle, styles[progressClasses.smallCircle3])}/>
                    </div>
                    <div className={helpers.clx(styles.progressStepName, styles[progressClasses.progressStepName3])}>
                        Create Account
                    </div>
                </div>
            </div>
    )
}

// const Circle = () => {
//     return <div
// }

OnBoardingProgressBar.defaultProps = {
    step: 0//Referal 0, Personal Details 1, Questionaire 2, Create Account 3, Account Created 4
  };

export default React.memo(OnBoardingProgressBar);