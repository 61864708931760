const questionsList = [
    {
        name: "What should I do if I'm logged out?",
        answer: "You should call us on our phone at +44 72 2323 92839 or perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm not logged out and trying to understamd?",
        answer: "Perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm logged out?",
        answer: "You should call us on our phone at +44 72 2323 92839 or perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm not logged out and trying to understamd?",
        answer: "Perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm logged out?",
        answer: "You should call us on our phone at +44 72 2323 92839 or perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm not logged out and trying to understamd?",
        answer: "Perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm logged out?",
        answer: "You should call us on our phone at +44 72 2323 92839 or perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm not logged out and trying to understamd?",
        answer: "Perhaps try to give us a call. You can also try browsing the ressources section of our website."
    },
    {
        name: "What should I do if I'm logged out?",
        answer: "You should call us on our phone at +44 72 2323 92839 or perhaps try to give us a call. You can also try browsing the ressources section of our website."
    }
]

export default questionsList;