import styles from './FAQ.module.css'; //styles
import helpers from '../../helpers.js';
import { useState } from 'react';
import questionsList from "./FAQList.js";
import iconHex from "../../assets/images/iconHexFaq.png";


const FAQ = (props) => {
    const [openedQuestion, setOpenedQuestion] = useState(-1);

    
    const selectQuestion = (i) => {
        if (i == openedQuestion) {
        setOpenedQuestion(-1);
        } else {
        setOpenedQuestion(i);
        }
    };

    let split = false;
    let splitIndex = questionsList.length;
    let questionsClass = "questions same-line";
    if (questionsList.length > 5) {
        split = true;
        questionsClass = "questions new-line";
        splitIndex = Math.floor(questionsList.length / 2) + (questionsList.length % 2);
    }


    return(
        <div className={styles.container}>
            <div className={styles.column}>
                {questionsList.map((question, i) => {
                    if (i < splitIndex) {
                        return (
                            <div className={styles.questionRow}>
                                <div className={styles.questionNumber}>
                                    {i < 9 ? "0" : ""}
                                    {i + 1}
                                </div>
                                <div className={styles.questionContent}>
                                    <div
                                    className={styles.questionName}
                                    onClick={() => {
                                        selectQuestion(i);
                                    }}
                                    >
                                    {question.name}
                                    </div>
                                    <div className={helpers.clx(styles.answer, openedQuestion === i ? styles.answerExpanded: "")}>
                                    {question.answer}
                                    </div>
                                </div>
                                <div onClick={() => {selectQuestion(i)}} className={styles.iconWrapper}>
                                     <img
                                        src={iconHex}
                                        role="button"
                                        tabIndex={0}
                                        className={helpers.clx(styles.iconHex, openedQuestion === i ? styles.iconHexSelected : "")}
                                       />
                                    <div className={styles.plusMinusWrapper}>
                                        {openedQuestion === i ? "-": "+"}
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                    return "";
                    }
                })}
            </div>
            {split ? (
                <div className={styles.column}>
                {questionsList.map((question, i) => {
                    if (i >= splitIndex) {
                        return (
                            <div className={styles.questionRow}>
                                <div className={styles.questionNumber}>
                                    {i < 9 ? "0" : ""}
                                    {i + 1}
                                </div>
                                <div className={styles.questionContent}>
                                    <div
                                    className={styles.questionName}
                                    onClick={() => {
                                        selectQuestion(i);
                                    }}
                                    >
                                    {question.name}
                                    </div>
                                    <div className={helpers.clx(styles.answer, openedQuestion === i ? styles.answerExpanded: "")}>
                                    {question.answer}
                                    </div>
                                </div>
                                <div onClick={() => {selectQuestion(i)}} className={styles.iconWrapper}>
                                     <img
                                        src={iconHex}
                                        role="button"
                                        tabIndex={0}
                                        className={helpers.clx(styles.iconHex, openedQuestion === i ? styles.iconHexSelected : "")}
                                       />
                                    <div className={styles.plusMinusWrapper}>
                                        {openedQuestion === i ? "-": "+"}
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                    return "";
                    }
                })}
            </div>
            ) : ("")
            }
        </div>
    )
}


export default FAQ;