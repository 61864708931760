
import styles from './CharcodesScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect, useRef, useCallback} from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';

import { UserContext } from '../UserContext.js';



import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Checkbox from "../components/Checkbox.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import Charcode from "../components/Charcode.js";
import logo from "../assets/images/bbbLogoText.png";



const params = {
  html2CanvasOptions: {
      backgroundColor: null,
      scale: 2
    }
};

function CharcodesScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [qrOnly, setQrOnly] = useState(false);
  const [charcodeAmount, setCharcodeAmount] = useState(0);
  const [charcode, setCharcode] = useState([])
  const [charcodes, setCharcodes] = useState([])
  const [selectedCharcodeIndex, setSelectedCharcodeIndex] = useState(0)
  const ref1 = useRef(null);
  const ref2 = useRef(null);


  const generateCharcodes = async () => {
    setCharcodes([])
    const request = {
      body: JSON.stringify({
        amount: charcodeAmount
      }),
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      }
    };
    console.log(user.backEndURL);
    let response = await fetch(user.backEndURL + "/charcodes", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log(jsonResponse.data)
      setCharcodes(jsonResponse.data.bags)
      setCharcode(jsonResponse.data.bags[0]);
    } else {
      console.log("Fetch Subscription Failed...")
    }
  }

  // useEffect(() => {
  //   console.log("Much Ado About Nothing...")
  // },[]) 

  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
  const exportCharcodes = async () => {
    for (let i = 0; i < charcodes.length; i++) {
      const code = charcodes[i];
      
      // Update the current charcode and index, triggering re-render.
      setCharcode(code);
      setSelectedCharcodeIndex(i);

      // Wait for the next render cycle before proceeding to the download
      await delay(600);  // Forces the UI to render the new charcode

      // Export the current QR code as PNG
      await exportComponentAsPNG(qrOnly ? ref2 : ref1, params);
      setLoading(true);

      // Simulate delay between exports
      await delay(1500);

      // Stop loading once the export is done
      setLoading(false);
    }
  };

  const renderCharcodes = useCallback(() => {
    if(loading || !charcodes || charcodes.length === 0){
      console.log("NO CHARCODES");
      return <div className={styles.placeholder}>No data to display, generate Charcodes first...</div>
    } else if(qrOnly){ 
      console.log(charcode)
 
      return (
            <div ref={ref2} className={styles.charcodeQrOnlyContainer}>
              <Charcode charcodeId={charcode}/>
            </div>
      )
    } else {
      return ( <div ref={ref1} className={styles.charcodesContainer}>
                <Charcode charcodeId={charcode}/>
                <img src={logo} className={styles.bbbLogo}/>
              </div>
        
  )
    }
  }, [loading, charcodes, selectedCharcodeIndex, charcode])


  
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/charcodes"}/>
        {/* {renderFarmerInvite()} */}

      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Generate Charcodes"} content={"Generate charcodes, export them and print them"}/>
          <div className={styles.contentContainer}>
            <div className={styles.dottedLine}>
             {renderCharcodes()}
            </div>
            <div className={styles.settingsContainer}>
              <div className={styles.settingsHeader}>
                Generator Settings
              </div>
              <TextInput value={charcodeAmount} onChange={setCharcodeAmount} name={"Number of Charcodes"} placeholder={"Enter Value"} labelStyle={"top"}/>
              <div className={styles.charcodesPerPage}>
                <TextInput name={"Charcodes per page"} placeholder={"Enter Value"} labelStyle={"top"} disabled={true}/>
              </div>
              <div className={styles.buttonsContainer}>
                <Button name={"Generate"} value={charcodeAmount} onPress={generateCharcodes} color={"Coal"}/>
                <Button name={"Download"} onPress={() => exportCharcodes()} disabled={charcodes.length === 0 ? true : false}/>
                <Checkbox text="Download Only QR" checked={qrOnly} onPress={() => setQrOnly(!qrOnly)}/>
              </div>

              
            </div>

          </div>
        </div>
        
      </div>
    </div>
  );
}


export default CharcodesScreen;
