import React from 'react';
import styles from './Button.module.css'; //styles
import helpers from '../helpers.js';



/*

<img/>
<div className="squareClassAndA"/>
    BUTTON
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onPress: setter from parent
            customStyle: TODO based on future requirements
*/
const Button = (props) => {

    let colorString = "Container" + props.color; //ContainerCoal
    console.log("render")

    return(
            <div style={props.customStyle} className={styles.ButtonContainer}>
                <div className={helpers.clx(styles.Container, props.disabled ? styles.ContainerDisabled : "", styles[colorString], props.selected ? styles.buttonSelected : "")}  onClick={!props.disabled ? props.onPress: null}>
                    {props.selected ? props.nameSelected : props.name}
                 </div>
            </div>
    )
}

Button.defaultProps = {
    name: 'InputName',
    disabled: false,
    color: "Leaf",
    onPress: console.log("Change onReleaseProp"), //set setState from parent
    iconName: "", //font-awesome 5 icon name
    customStyle: {},
    selected: false,
    nameSelected: ""
};

export default React.memo(Button);