import styles from './WeekPopup.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';

import Selector from "./Selector.js";
import TextInput from "./TextInput.js";
import Checkbox from "./Checkbox.js";
import Button from "./Button.js";
import CharcodeTag from "./CharcodeTag.js";
import selectArrowGrey from "../assets/images/selectArrowGrey.png";


const eventTypes = [
    {name: 'Order', value: 'order'}, 
    {name: 'Other', value: 'other'}, 
    {name: 'Machine Log', value: 'machineLog'}
];

const sites = [
    {name: 'Ahlstrom', value: 'ARA'}, 
    {name: 'Jenkinson', value: 'JNR'},
];

const farmers = [
    {name: 'John Snow', value: '23u89343e'}, 
    {name: 'Leroy Jenkins', value: 'sdsda231d'},
];

/*
    EventPopup
        props:
            checked: boolean, sets EventPopup status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before EventPopup 
*/

const date = new Date();
const WeekPopup = (props) => {
    console.log(props)
    const [eventType, setEventType] = useState("order-new");
    const [isOrder, setIsOrder] = useState(true);
    const [isEditingNotes, setIsEditingNotes] = useState(false);
    const [newNotes, setNewNotes] = useState(props.displayedWeek ? props.displayedWeek.notes : "");
    

    const [formData, setFormData] = useState({
        eventType: {name: "Order", value: "order"},
        site: sites[0],
        date: new Date(),
        time: "00:00",
        farmer:  {name: 'John Snow', value: '23u89343e'},
        bags: 0,
        isNewDelivery: true,
        deliveryId: "",
        orderID: "ORD-2313",
        comment: ""
    });
    const [weekDates, setWeekDates] = useState({
        startDate: null,
        endDate: null
    })
    const [bags, setBags] = useState(["CHA-000130", "CHA-000131", "CHA-000132", "CHA-000133", "CHA-000134", "CHA-000135", "CHA-000136", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133"])
    const [collapseBags, setCollapseBags] = useState(false);
    // const check = () => {
    //     // setIsChecked((prevIsChecked) => !prevIsChecked);
    //     props.onPress();
    // }

    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    const handleChange = (formField, value) => {
        setFormData({ ...formData, [formField]: value });
      };

    const handleSubmit = () => {

        return;
        
    }

    const changeWeek = (isNext) => {
        let weekIndex = 0;
        props.weekSlots.forEach((week, index) => {
            if(index === props.weekSlots.length - 1 && isNext){
                weekIndex = props.weekSlots.length - 1;
            }else if(week === props.displayedWeek && index !== 0 ){
                weekIndex = index + (isNext ? 1 : -1);
                // props.setDisplayedWeek(props.weekSlots[weekIndex]);
                // return;
             }
        })
        props.setDisplayedWeek(props.weekSlots[weekIndex]);
    }

    const getStartAndEndDate = (date) => {
        // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        const dayOfWeek = date.getDay();
    
        // Calculate the start date by subtracting the day of the week from the input date
        const startDate = new Date(date);
        startDate.setDate(startDate.getDate() - dayOfWeek);
    
        // Calculate the end date by adding the difference between the day of the week and 6 (for Sunday)
        const endDate = new Date(date);
        endDate.setDate(endDate.getDate() + (6 - dayOfWeek));
    
       
        // Format the dates
        const startDateString = startDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
        const endDateString = endDate.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    
        // Return the formatted start and end dates
        return `${startDateString} - ${endDateString}`;
    }

    const renderHeaderBar = () => {
        console.log(props.displayedWeek)

        return (
            props.displayedWeek ? 
                <div className={styles.headerBar}>
                        
                {/* <div> */}
                    <span className={styles.headerIcon} onClick={() => changeWeek(false)}>
                    chevron-left
                    </span>
                    {getStartAndEndDate(new Date(props.displayedWeek.startDate))}
                    <span className={styles.headerIcon} onClick={() => changeWeek(true)}>
                    chevron-right
                    </span>
                {/* </div> */}
            
                {props.editingEvent ? <div className={styles.headerOrderId}><span className={styles.headerIcon}>qrcode</span>ORD-3813</div> : null}
            </div>
            : null    
        )
    }

    const renderEvents = () => {
        return (
            <div className={helpers.clx(styles.eventRowsContainer)}>
                {props.displayedWeek.events.map((event) => {
                    console.log(event);
                    return(
                        <div className={styles.eventContainer}>
                            <div className={styles.eventHeaderRow}>
                                <div className={styles.eventHeaderRowSection}>
                                    <div className={styles.orderTag}>
                                        <span>#</span>{event.order.id}
                                    </div>
                                    <div className={styles.eventHeader}>
                                      Biochar Order
                                    </div>
                                </div>
                                <div className={styles.eventHeaderRowSection}>
                                    <div className={styles.eventHeaderField}>
                                        <span className={styles.iconFontAwesome}>
                                            check-circle
                                        </span> 
                                        Assigned
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eventContent}>
                                <div className={styles.eventColumn}>
                                    <div className={styles.commentHeader}>
                                        Comment
                                    </div>
                                    <div className={styles.commentContent}>
                                        {event.order.comment}
                                    </div>
                                </div>
                                <div className={styles.eventColumn}>
                                    <div className={styles.eventRow}>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                truck
                                            </span>
                                            {event.order.deliveryId}
                                        </div>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                shopping-bag
                                            </span>
                                            {event.order.bags}
                                        </div>
                                    </div>
                                    <div className={styles.eventRow}>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                user
                                            </span>
                                            {event.order.farmerFirstName} {event.order.farmerLastName}
                                        </div>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                arrow-up
                                            </span>
                                            {formatDate(event.order.pickupDate)}
                                        </div>
                                    </div>
                                    <div className={styles.eventRow}>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                warehouse-alt
                                            </span>
                                            {event.order.site}
                                        </div>
                                        <div className={styles.eventStat}>
                                            <span className={styles.iconFontAwesome}>
                                                arrow-down
                                            </span>
                                            {formatDate(event.order.deliveryDate)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderOverlayContent = () => {
        console.log(props)
        return (
            <>
                <div className={styles.firstRow}>
                    <div className={styles.inventoryContainer}>
                        <div className={styles.inventoryHeaderRow}>
                            <div className={styles.inventoryHeader}>
                                Inventory
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                ARA
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                JNR
                            </div>
                        </div>
                        <div className={styles.inventoryRow}>
                            <div className={styles.inventoryField}>
                                Weekly Production
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                        </div>
                        <div className={helpers.clx(styles.inventoryRow, styles.inventoryRowTransparent)}>
                            <div className={styles.inventoryField}>
                                Est. Weekly Production
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                        </div>
                        <div className={styles.inventoryRow}>
                            <div className={styles.inventoryField}>
                                Bags Delivered
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                        </div>
                        <div className={helpers.clx(styles.inventoryRow, styles.inventoryRowTransparent)}>
                            <div className={styles.inventoryField}>
                                Current Stock
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                        </div>
                        <div className={styles.inventoryRow}>
                            <div className={styles.inventoryField}>
                                Forecasted Stock
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                            <div className={styles.inventorySiteHeader}>
                                6
                            </div>
                        </div>
                    </div>
                    <div className={styles.notesContainer}>
                        <div className={styles.notesHeaderRow}>
                            <div className={styles.notesHeader}>
                                Notes
                            </div>
                            {
                                isEditingNotes?
                                <div>
                                    <div className={helpers.clx(styles.notesIcon, styles.confirmIcon)} onClick={() => setIsEditingNotes(true)}>check-square</div>
                                    <div className={helpers.clx(styles.notesIcon, styles.closeIcon)} onClick={() => setIsEditingNotes(false)}>window-close</div>
                                </div>
                                :
                                <div className={styles.notesIcon} onClick={() => setIsEditingNotes(true)}>
                                    pencil
                                </div>
                            }
                        </div>

                            
                            
                        <textarea value={newNotes} onChange={(e) => setNewNotes(e.target.value)} className={helpers.clx(styles.notesText, isEditingNotes ? styles.notesTextEditing : "")} readOnly={isEditingNotes ? false : true}/>
                       
                        
                    </div>
                </div>
                <div className={styles.eventsContainer}>
                    <div className={styles.eventsHeader}>
                        Events
                    </div>
                   {renderEvents()}
                    <div className={styles.addEvent}>
                        + Add Event
                    </div>
                </div>
            </>
        )
        
    }
    if(!props.displayWeekPopup){
        return;
    }
    return(
        <div className={styles.overlayBg}>
            <div className={styles.overlayClosePopup} onClick={() => props.closePopup()}/>
            <div className={styles.overlay}>
                {renderHeaderBar()}
              <div className={styles.overlayContent}>
                {renderOverlayContent()}
              </div>
            </div>
        </div>
    )

}

WeekPopup.defaultProps = {
    thinnerText: false,
    checked: false,
    onPress: console.log("Set EventPopu onPress"),
    text: "Change EventPopu text prop",
    inverted: false,
    closePopup: () => alert("set ClosePopup prop")
};



export default WeekPopup;

function formatDate(date) {
    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const day = daysOfWeek[date.getDay()];
    const dateOfMonth = date.getDate();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;

    return `${day} ${dateOfMonth} - ${hours}:${minutesFormatted} ${ampm}`;
}
