import {useState} from "react";
import styles from './Tooltip.module.css'; //styles
import helpers from '../helpers.js';




/*
    STANDARD TEXT INPUT
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onChange: setter from parent
            height: sets height, and turns it into textarea
            customStyle: TODO based on future requirements
*/
const Tooltip = (props) => {
    const [displayTip, setDisplayTip] = useState(false);
    const returnClassSize = () => {
        switch(props.size){
            case "small":
                return styles.small;
            case "medium":
                return styles.medium;
            case "large":
                return styles.large;
            default: 
                return ""
        }
    }

    let arrowClass = "";
    let bubbleClass = "";
    switch(props.direction){
        case "topLeft":
            bubbleClass = styles.topLeft;
            arrowClass = styles.arrowTopLeft;
            break;
        case "topRight":
            bubbleClass = styles.topRight;
            arrowClass = styles.arrowTopRight;
            break;
        case "bottomLeft":
            bubbleClass = styles.bottomLeft;
            arrowClass = styles.arrowBottomLeft;
            break;
        case "bottomRight":
            bubbleClass = styles.bottomRight;
            arrowClass = styles.arrowBottomRight;
            break;
    }

    
    return(
        <>
            <span className={helpers.clx(styles.tooltip, returnClassSize())}>
                {props.type === "i" ? "info-circle" : "question-circle"}
                <div className={styles.hoverArea}  onMouseEnter={() => setDisplayTip(true)} onMouseLeave={() => setDisplayTip(false)}/>
                <div style={{minWidth: props.minWidth, maxWidth: props.maxWidth}} className={helpers.clx(styles.bubble, bubbleClass, !displayTip ? styles.bubbleHidden : "")}>
                    <div className={arrowClass}/>
                    {props.tip}
                </div>
            </span>
        </>
    )
}

Tooltip.defaultProps = {
    tip: "Enter a tip prop to display this message correctly.",
    type: "i",
    color: "c",
    size: "large",
    minWidth: "20rem",
    maxWidth: "100rem",
    direction: "topRight"
  };

export default Tooltip;