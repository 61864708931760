
import styles from './AnalyticsScreen.module.css';
import helpers from './helpers.js';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";


function AnalyticsScreen(props) {
  // console.log(helpers.clx(styles.MainBlack, "green"));
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"analytics"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Analytics"} content={"Have a look at how you're doing with all your biochar related data. For optimal results, make sure to fill in the MRV section."}/>
          Analytics CONTENT
        </div>
      </div>
    </div>
  );
}

export default AnalyticsScreen;
