import styles from './EventPopup.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';

import Selector from "./Selector.js";
import TextInput from "./TextInput.js";
import Checkbox from "./Checkbox.js";
import Button from "./Button.js";
import CharcodeTag from "./CharcodeTag.js";
import selectArrowGrey from "../assets/images/selectArrowGrey.png";


const eventTypes = [
    {name: 'Order', value: 'order'}, 
    {name: 'Other', value: 'other'}, 
    {name: 'Machine Log', value: 'machineLog'}
];

const sites = [
    {name: 'Ahlstrom', value: 'ARA'}, 
    {name: 'Jenkinson', value: 'JNR'},
];

const farmers = [
    {name: 'John Snow', value: '23u89343e'}, 
    {name: 'Leroy Jenkins', value: 'sdsda231d'},
];

/*
    EventPopup
        props:
            checked: boolean, sets EventPopup status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before EventPopup 
*/
const EventPopup = (props) => {
    const [eventType, setEventType] = useState("order-new");
    const [isOrder, setIsOrder] = useState(true);
    const [formData, setFormData] = useState({
        eventType: {name: "Order", value: "order"},
        site: sites[0],
        date: new Date(),
        time: "00:00",
        farmer:  {name: 'John Snow', value: '23u89343e'},
        bags: 0,
        isNewDelivery: true,
        deliveryID: "",
        orderID: "ORD-0013",
        deliveryID: "DEL-00013",
        comment: ""
    });
    const [bags, setBags] = useState(["CHA-000130", "CHA-000131", "CHA-000132", "CHA-000133", "CHA-000134", "CHA-000135", "CHA-000136", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133","CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133", "CHA-000133"])
    const [collapseBags, setCollapseBags] = useState(false);
    // const check = () => {
    //     // setIsChecked((prevIsChecked) => !prevIsChecked);
    //     props.onPress();
    // }

    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    const handleChange = (formField, value) => {
        setFormData({ ...formData, [formField]: value });
      };

    const handleSubmit = () => {

        return;
        
    }
    const renderHeaderBar = () => {

        return (
            <div className={styles.headerBar}>
                <div>
                    <span className={styles.headerIcon}>
                      {props.editingEvent ? "pencil" : "calendar-alt"}
                    </span>
                    {props.editingEvent ? "Edit Order" : "New Event"}
                </div>
               
                {props.editingEvent ? <div className={styles.headerOrderId}><span className={styles.headerIcon}>qrcode</span>ORD-3813</div> : null}
            </div>
        )
    }

    const renderCharcodeTags = () => {

        return (
            <div className={helpers.clx(styles.bagsContainer, collapseBags ? styles.bagsContainerCollapsed : "")}>
                {bags.map((bag) => {
                    return(<CharcodeTag charcodeId={bag}/>)
                })}
            </div>
        )
    }

    const renderOverlayContent = () => {
 

        return (
            <>
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer, styles.selectHighZIndex)}>
                        {
                            props.editingEvent ? 
                            <Selector name="Farmer" value={formData.farmer} data={farmers} onChange={(value) => handleChange("farmer", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Country" color="white"/>
                             :
                            <Selector name="Event Type" value={formData.eventType} data={eventTypes} onChange={(value) => handleChange("eventType", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Country" color="white"/>
                        }
                    </div>
                    <div className={styles.inputContainer}>
                        <Selector name="Site" value={formData.site} data={sites} onChange={(value) => handleChange("site", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Select Country" color="white"/>
                    </div>
                </div>
                <div className={styles.overlayRow}>
                    <div className={styles.inputContainer}>
                        <TextInput name="Date" value={formData.date} data={[]} onChange={(value) => handleChange("date", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Select Date" color="white"/>
                    </div>
                    <div className={styles.inputContainer}>
                        <TextInput name="Time" value={formData.time} onChange={(value) => handleChange("time", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Select Country" color="white"/>
                    </div>
                </div>
                {
                    props.editingEvent ? 
                        null
                        : 
                        <div className={styles.overlayRow}>
                            <div className={styles.inputContainer}>
                            <Selector name="Farmer" value={formData.farmer} data={farmers} onChange={(value) => handleChange("farmer", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Country" color="white"/>
                            </div>
                            <div className={styles.inputContainer}>
                                <TextInput name="# of Bags" value={formData.bags} onChange={(value) => handleChange("bags", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="# of Bags" color="white"/>
                            </div>
                        </div>
                }
               
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer)}>
                        {
                            props.editingEvent ? 
                            <Selector name="Order ID" value={formData.deliveryID} data={eventTypes} onChange={(value) => handleChange("deliveryID", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Delivery ID" color="white"/>
                            :
                            <Checkbox text="Assign New Delivery" checked={formData.isNewDelivery} data={eventTypes} onPress={() => handleChange("isNewDelivery", !formData.isNewDelivery)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Country" color="white"/>

                        }
                    </div>
                    <div className={styles.inputContainer}>
                        <Selector name="Delivery ID" value={formData.deliveryID} data={eventTypes} onChange={(value) => handleChange("deliveryID", value)} highlighted={"highlighedtInput" === "country" ? true : true} labelStyle="top" placeholder="Select Delivery ID" color="white"/>
                    </div>
                </div>
                {
                     props.editingEvent ? 
                        <div className={styles.overlayRow}>
                            <div className={helpers.clx(styles.inputContainer, styles.inputFullRowContainer)}>
                                <div className={styles.bagsLabelContainer} onClick={() => setCollapseBags(!collapseBags)}>
                                <div>
                                        Bags {"(" + bags.length + ")"}
                                    </div>
                                    <img src={selectArrowGrey} className={helpers.clx(styles.collapseIcon, collapseBags ? styles.collapseIconFlipped : "")} />
                                </div>
                                {renderCharcodeTags()}
                            
                            </div>
                        </div>
                      : null
                }
                
                <div className={styles.overlayRow}>
                    <div className={helpers.clx(styles.inputContainer, styles.inputFullRowContainer)}>
                        <TextInput name="Comment" value={formData.comment} onChange={(value) => handleChange("comment", value)} highlighted={"highlighedtInput" === "country" ? true : false} labelStyle="top" placeholder="Add Comment" color="white" height={"8rem"}/>
                    </div>
                </div>
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={props.closePopup} name="Cancel" color="Error"/>
                    <Button disabled={false} onPress={handleSubmit} name="Add New Event" color="Leaf"/>
                </div>
            </>
        )
        
    }
    if(!props.displayEventPopup){
        return;
    }
    return(
        <div className={styles.overlayBg}>
            <div className={styles.overlayClosePopup} onClick={() => props.closePopup()}/>
            <div className={styles.overlay}>
                {renderHeaderBar()}
              <div className={styles.overlayContent}>
                {renderOverlayContent()}
              </div>
            </div>
        </div>
    )
}

EventPopup.defaultProps = {
    thinnerText: false,
    checked: false,
    onPress: console.log("Set EventPopu onPress"),
    text: "Change EventPopu text prop",
    inverted: false,
    closePopup: () => alert("set ClosePopup prop")
  };

export default EventPopup;