import styles from './TagSelector.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';



/*
    Tag Selector
        props:
            tags: ["Tag 1", "Tag 2", "Tag 3"],
            selectedTags: ["Tag 3"],
            updateTags: console.log("Set updateTags props"),
            name: "Change Tag Selector name prop",
            tagColor: "leaf"
*/
const TagSelector = (props) => {

    const renderTags = () => {
        // setIsChecked((prevIsChecked) => !prevIsChecked);
        return(
            <>
                {props.tags.map((tag)=> {
                    return(
                        <div className={helpers.clx(styles.Tag, props.selectedTags.includes(tag) ? styles.TagSelected : "" )} onClick={() => props.updateTags(tag)}>
                            {tag}
                        </div>
                    )
                })}
            </>
        )
    }

    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    return(
        <div className={styles.TagSelector}>
            <div className={styles.TagHeader}>
                {props.name}
            </div>
            <div className={styles.TagsRow}>
                {renderTags()}
            </div>
        </div>
    )
}

TagSelector.defaultProps = {
    tags: ["Tag 1 yolo", "Tag 2 Maybe", "Tag 3 Hello Why", "Tag 2 Maybe", "Tag 3 Hello Why", "Tag 2 Maybe", "Tag 3 Hello Why", "Tag 2 Maybe", "Tag 3 Hello Why"],
    selectedTags: ["Tag 3"],
    updateTags: console.log("Set updateTags props"),
    name: "Change Tag Selector name prop",
    tagColor: "leaf"
  };

export default TagSelector;