
import styles from './Filters.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';




import Checkbox from "../../components/Checkbox.js";

const farmerList = [
    {name: "Ian Wright", checked: true},
    {name: "John Snow", checked: true},
    {name: "Louis Armstrong", checked: true},
    {name: "Harry Otter", checked: true},
    {name: "Julius Caesar", checked: true},
    {name: "Mari Kondon", checked: true}
]
  /*
  Checkbox.defaultProps = {
    checked: false,
    onPress: console.log("Set CheckBox onPress"),
    text: "Change checkbox text prop",
    inverted: false
  };
  */


function Filters(props) {
    
    const [eventType, setEventType] = useState({
        order: false,
        invoices: false,
        machineLogs: false,
        other: false
    });
    const [eventLocation, setEventLocation] = useState({
        all: true,
        ahlstrom: true,
        jenkinson: true,
        other: true
    });
    
    const [farmers, setFarmers] = useState(farmerList);
    
    const [collapsedFilters, setCollapsedFilters] = useState({
        type: false,
        location: false,
        farmers: false
    });

      
    const handleChangeType = (formField, value) => {
        setEventType({ ...eventType, [formField]: value });
    };

    const handleChangeEventLocation = (formField, value) => {
        setEventLocation({ ...eventLocation, [formField]: value });
    };

    const handleChangeFarmers = (farmerIndex, value) => {
        console.log(farmerIndex)
        let farmerz = farmers;
        farmerz[farmerIndex].checked = !farmerz[farmerIndex].checked
        console.log(farmers);
        setFarmers(farmerz);
    };
//   useEffect(() => {
//     // console.log("Fetching Farmers")
   
//     // fetchFarmers();
//   },[user])

    return (
        <div className={styles.filtersContainer}>
            <div className={styles.header}>
                Filter Events
            </div>
            <div className={helpers.clx(styles.filtersSection, collapsedFilters.type ? styles.filtersSectionCollapsed : null)}>
                <div className={styles.sectionHeader} onClick={() => setCollapsedFilters({ ...collapsedFilters, type: !collapsedFilters.type })}>
                    <div>Type</div>
                    <div className={styles.expandIcon}>{collapsedFilters.type ? "chevron-down" : "chevron-up"}</div>
                </div>
                <div className={styles.checkboxesContainer}>
                    <Checkbox thinnerText={true} text="Orders" onPress={() => handleChangeType("order", !eventType.order)} checked={eventType.order}/>
                    <Checkbox thinnerText={true} text="Invoices" onPress={() => handleChangeType("invoices", !eventType.invoices)} checked={eventType.invoices}/>
                    <Checkbox thinnerText={true} text="Machine Logs" onPress={() => handleChangeType("machineLogs", !eventType.machineLogs)} checked={eventType.machineLogs}/>
                    <Checkbox thinnerText={true} text="Other" onPress={() => handleChangeType("other", !eventType.other)} checked={eventType.other}/>
                </div>
            </div>
            <div className={helpers.clx(styles.filtersSection, collapsedFilters.location ? styles.filtersSectionCollapsed : null)}>
                <div className={styles.sectionHeader} onClick={() => setCollapsedFilters({ ...collapsedFilters, location: !collapsedFilters.location })}>
                    <div>Location</div>
                    <div className={styles.expandIcon}>{collapsedFilters.location ? "chevron-down" : "chevron-up"}</div>
                </div>
                <div className={styles.checkboxesContainer}>
                    <Checkbox thinnerText={true} text="All" onPress={() => handleChangeEventLocation("all", !eventLocation.all)} checked={eventLocation.all}/>
                    <Checkbox thinnerText={true} text="Ahlstrom" onPress={() => handleChangeEventLocation("ahlstrom", !eventLocation.ahlstrom)} checked={eventLocation.ahlstrom}/>
                    <Checkbox thinnerText={true} text="Jenkinson" onPress={() => handleChangeEventLocation("jenkinson", !eventLocation.jenkinson)} checked={eventLocation.jenkinson}/>
                    <Checkbox thinnerText={true} text="Other" onPress={() => handleChangeEventLocation("other", !eventLocation.other)} checked={eventLocation.other}/>
                </div>
            </div>
            <div className={helpers.clx(styles.filtersSection, collapsedFilters.farmers ? styles.filtersSectionCollapsed : null)}>
                <div className={styles.sectionHeader} onClick={() => setCollapsedFilters({ ...collapsedFilters, farmers: !collapsedFilters.farmers })}>
                    <div>Farmers</div>
                    <div className={styles.expandIcon}>{collapsedFilters.farmers ? "chevron-down" : "chevron-up"}</div>
                </div>
                <div className={styles.checkboxesContainer}>
                    {farmers.map((farmer, i) => {
                      return  <Checkbox thinnerText={true} text={farmer.name} onPress={() => handleChangeFarmers(i)} checked={farmer.checked}/>
                    })}
                </div>
            </div>
        </div>
    );
    }
//

export default Filters;
