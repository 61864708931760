
import styles from './InventoryScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../UserContext.js';

import FarmerDetails from './farmers/FarmerDetails.js'
import SubscriptionDetails from './farmers/SubscriptionDetails.js'
import Sites from './inventory/Sites.js';
import Bags from './inventory/Bags.js';



import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import Overlay from "../components/Overlay.js";


function InventoryScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");


  const fetchFarmers = async () => {
    // const request = {
    //   method: "get",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + user.token,
    //   }
    // };
    // console.log(user.backEndURL);
    // let response = await fetch(user.backEndURL + "/users", request);
    // let jsonResponse = await response.json();
    // if (jsonResponse.success) {
    //   console.log(jsonResponse);
    //   // let newFarmers = [];
    //   // jsonResponse.data.forEach((farmer) => {
    //   //   let farmerDate = new Date(farmer.delivery_date)
    //   //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
    //   //   let newFarmer = {
    //   //     id: farmer._id,
    //   //     status: farmer.status,
    //   //     deliveryDate: dateString,
    //   //     pincharBags: farmer.products[0].amount,
    //   //     pelletcharBags: farmer.products[1].amount,
    //   //     weight: 2.2,
    //   //     volume: 8
    //   //   }
    //   //   newFarmers.push(newFarmer);
    //   // })

    //   setFarmers(jsonResponse.data);
    //   console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    // } else {
    //   console.log("Fetch Subscription Failed...")
    // }
  }

  useEffect(() => {
    console.log("Fetching Farmers")
   
    fetchFarmers();
  },[user])

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/inventory"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <div className={styles.inviteFarmerButton}>
          </div>
          <ScreenHeader name={"Inventory"} content={"Browse through your biochar farmers. Don’t hesitate to contact us if you have any questions!"}/>
          <Sites/>
          <Bags/>
        </div>
      </div>
    </div>
  );
}


export default InventoryScreen;
