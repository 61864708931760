import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './Invoices.module.css';
import helpers from '../../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../../UserContext.js';


import TextInput from "../../components/TextInput.js";
import Button from "../../components/Button.js";
import Overlay from "../../components/Overlay.js";
import Selector from "../../components/Selector.js";
import ProductCounter from "../../components/subscription/ProductCounter.js";







function Invoices(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [displayNewInvoiceOverlay, setDisplayNewInvoiceOverlay] = useState(false);
  const [invoiceToUpdate, setInvoiceToUpdate] = useState(null);



  const [formData, setFormData] = useState({
    amountDue: 0,
    xeroPdfLink: "",
    dueDate: new Date()
  });

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };
  

  const createNewInvoice = async () => {
    const request = {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        userId: props.farmer._id,
        dueDate: formData.dueDate,
        amountDue: formData.amountDue,
        xeroPdfLink: formData.xeroPdfLink
      })
    };
    let response = await fetch(user.backEndURL + "/invoice", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      alert("Successfully created new invoice!");
      setDisplayNewInvoiceOverlay(false);
      props.updateFarmerList();
    } else {
      alert("Something went wrong...")
    }
    
  }

  const renderNewInvoice = () => {
    if(displayNewInvoiceOverlay){
      return(
        <Overlay>
          <div className={styles.scheduleInvoiceContainer}>
            <h2>New Invoice</h2>
            <div className={styles.newInvoiceRow}>
              Invoice Due Date:
              {/* <DatePicker className={styles.datePicker} calendarClassName={styles.calendar} selected={formData.date} onChange={(date) => handleChange("date", date)} /> */}
              <input className={styles.datePicker} type="date" value={formData.dueDate} onChange={(e) => handleChange("dueDate", e.target.value)}/>
            </div>
            <div className={styles.newInvoiceRow}>
              <TextInput name="Amount Due" value={formData.amountDue} onChange={(value) => handleChange("amountDue", value)} iconName="pound-sign" labelStyle="top" placeholder="Enter Amount"/>
            </div>
            <div className={styles.newInvoiceRow}>
              <TextInput name="Xero Link" value={formData.xeroPdfLink} onChange={(value) => handleChange("xeroPdfLink", value)} iconName="file-pdf" labelStyle="top" placeholder="Enter Xero Link"/>
            </div>
          <Button name={"Create Invoice"} onPress={() => createNewInvoice()}/>
          <Button name={"Cancel"} color={"Error"} onPress={() => setDisplayNewInvoiceOverlay(false)}/>
  
          </div>
        </Overlay>
      )
    }else{
      return null
    }
  }

  const updateInvoice = async (isDelete) => {
    const request = {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.token,
      },
      body: JSON.stringify({
        invoiceId: invoiceToUpdate._id,
        userId: props.farmer._id, 
        status: isDelete ? "delete" : invoiceToUpdate.status
      })
    };
    let response = await fetch(user.backEndURL + "/invoice", request);
    let jsonResponse = await response.json();
    if (jsonResponse.success) {
      alert("Successfully Updated!");
      setInvoiceToUpdate(null);
      props.updateFarmerList();
    } else {
      alert("Something went wrong...")
    }
    
  }

  const renderUpdateInvoice = () => {
    if(invoiceToUpdate === null){
      return;
    }
    let invoiceDate = new Date(invoiceToUpdate.due_date);
    let selectorData = [
      {name: "Upcoming", value: "upcoming"},
      {name: "Paid", value: "paid"},
      {name: "Late", value: "late"},
      {name: "Cancelled", value: "cancelled"}
    ]
    return(
      <Overlay>
        <div className={styles.scheduleInvoiceContainer}>
           <h2>Update Invoice</h2>
          <div className={styles.updateInvoiceStatus}></div>
          <div className={styles.updateInvoiceRow}>
           ID:{" " + invoiceToUpdate._id}
          </div>
          <div className={styles.updateInvoiceRow}>
           Amount Due:{" £ " + invoiceToUpdate.amount_due}
          </div>
          <div className={styles.updateInvoiceRow}>
            Due Date:{" " +invoiceDate.getDate() + "/" + (invoiceDate.getMonth()+1) + "/" + invoiceDate.getFullYear()}
          </div>
          <div className={styles.updateInvoiceRow}>
            <Selector name="Status" value={invoiceToUpdate.status} data={selectorData} onChange={(value) => setInvoiceToUpdate({...invoiceToUpdate, status: value})}  labelStyle="top" placeholder="Select Status" color="white"/>
          </div>
        <Button name={"Update Invoice"} onPress={() => updateInvoice(false)}/>
        <Button name={"Delete Invoice"} color={"Error"} onPress={() => updateInvoice(true)}/>
        <Button name={"Cancel"} color={"Coal"} onPress={() => setInvoiceToUpdate(null)}/>


        </div>
      </Overlay>
    ) 
  }

  const openXeroPdf = (invoice) => {

  }
  const renderInvoices = () => {
    console.log(props.farmer);
    let filteredInvoices = props.farmer._invoices;//[];
    console.log(filteredInvoices);
  //   if(selectedFilter === "all"){
  //     filteredInvoices = invoices;
  //   }else {
  //     filteredInvoices = invoices.filter(function (invoice) {
  //       return invoice.status === selectedFilter 
  //     }
  //   );
  // }

  
  
    if(filteredInvoices.length > 0){
      return(
        <>
          {filteredInvoices.map(invoice => {
            let invoiceDate = new Date(invoice.due_date);
            return(
              <tr className={styles.tableRow}>
                <td className={styles.dataBold}>#{invoice._id}</td>
                <td><span className={styles.dataBold}>{renderStatus(invoice.status)}</span></td>
                <td><span className={styles.dataBold}>{invoiceDate.getDate() + "/" + (invoiceDate.getMonth()+1) + "/" + invoiceDate.getFullYear()} </span></td>
                <td><span className={styles.dataBold}>£{" " + invoice.amount_due} </span></td>
                <td className={styles.tableRowLinks}><a className={helpers.clx(styles.editIcon, typeof invoice.xero_pdf_link !== "string" || invoice.xero_pdf_link.length === 0 ? styles.linkDisabled : "")} style={{display: "table-cell"}} href={invoice.xero_pdf_link} target="_blank">link</a><span className={styles.editIcon} onClick={() => {setInvoiceToUpdate(invoice)}}>edit</span></td>
              </tr>
            ) 
          })
        }
        </>
      )
    } else {
      return (
        <div className={styles.invoicesPlaceholder}>
          No data to display...
        </div>
      )
    }
    
  }
  
  return (
    <div className={styles.container}>
      {renderNewInvoice()}
      {renderUpdateInvoice()}
      <div className={styles.invoicesContainer}>
        <div className={styles.invoicesListHeader}>Invoices</div>
        <table className={styles.invoiceTable}>
          <tr className={styles.invoiceHeaderRow}>
            <th>ID</th>
            <th>Status</th>
            <th>Due Date</th>
            <th>Amount Due</th>
          </tr>
          {renderInvoices()}
        </table>
        {/* <div className={styles.invoicesListColumn}>
        Hello
      </div> */}
      </div>
      <div className={styles.buttonRow}>
        <div className={styles.buttonContainer}>
         <Button name={"New Invoice"} color={"Coal"} onPress={() => setDisplayNewInvoiceOverlay(true)}/>
        </div>
      </div>


    </div>
  );
}

const renderStatus = (status) => {
  switch(status){
    case "paid":
      return <td className={styles.statusPaid}><span className={styles.statusIcon}>check-circle</span>Paid</td>
    case "upcoming":
      return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
    case "late":
      return <td className={styles.statusLate}><span className={styles.statusIcon}>clock</span>Late</td>
    case "cancelled":
      return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
    default:
        return <td>N/A</td>
  }

}

export default Invoices;
