import React, {useMemo, useState, useContext} from 'react';
import { UserContext } from "../../UserContext.js";

import styles from './ReferralContent.module.css'; //styles
import helpers from '../../helpers.js';

import TextInput from "../TextInput.js";
import Tooltip from "../Tooltip.js";

import Button from "../Button.js";

import greenCircles from "../../assets/images/greenCirclesCorner.png";
import bbbLogo from "../../assets/images/bbbLogo.png";


/*
    
        props:
            name: 
*/

const ReferralContent = (props) => {
    const {user, setUser} = useContext(UserContext);

    const [errorPrompt, setErrorPrompt] = useState("");

    const handleSubmit = async () => {
      //REMOVE THIS LINE
        // props.nextStep();

        if(typeof props.formData.referralCode === "undefined" || props.formData.referralCode.length !== 8){
            helpers.errorPrompt("Please enter a valid referral code", null, setErrorPrompt, null)
            return;
        }
        const request = {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              referralCode: props.formData.referralCode
            }),
          };
      
          let response = await fetch(user.backEndURL + "/validate-referral", request);
          let jsonResponse = await response.json();
          if (jsonResponse.success) {
            props.handleChange("email", jsonResponse.data)
            props.nextStep();
          } else {
            helpers.errorPrompt("Your referral code is invalid", null, setErrorPrompt, null)
            return;
          }
    }

    return(
        <> 
            <div className={styles.logoContainer}>
                <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
            </div>
            <div className={styles.greenCirclesWrapper}>
                <img src={greenCircles} className={helpers.clx(styles.greenCircle1, props.transitioning ? styles.greenCircle1Hidden : "")} alt="Green Circle"/>
                <img src={greenCircles} className={helpers.clx(styles.greenCircle2, props.transitioning ? styles.greenCircle2Hidden : "")} alt="Green Circle"/>
            </div>
            <div className={helpers.clx(styles.referralContent, props.transitioning ? styles.referralContentHidden : "")}>
                <h2 className={styles.loginHeader}>
                    Please Enter Referral Code
                    <Tooltip tip="You should have received a referral code in your invite from your supervisor"/>
                </h2>
                <TextInput name="Referral Code" value={props.formData.referralCode} onChange={(value) => props.handleChange("referralCode", value)} iconName="key" labelStyle="top" placeholder="Enter Code"/>
                <Button onPress={handleSubmit} name="Next" color="Coal"/>
                <div className={helpers.clx("errorPrompt", styles.errorPromptContainer)}>{errorPrompt}</div>

            </div>
        </>
    )
}


ReferralContent.defaultProps = {
    step: 0//Referal 0, Personal Details 1, Questionaire 2, Create Account 3, Account Created 4
  };

export default React.memo(ReferralContent);