import React, {useMemo, useState} from 'react';
import styles from './DetailsContent.module.css'; //styles
import helpers from '../../helpers.js';

import TextInput from "../TextInput.js";
import Selector from "../Selector.js";
import Button from "../Button.js";

import bbbLogo from "../../assets/images/bbbLogo.png";
import countries from "../../assets/Countries.js";

const DetailsContent = (props) => {
    const [errorPrompt, setErrorPrompt] = useState("");
    const [highlighedtInput, setHighlighedtInput] = useState("");


    const handleSubmit = () => {
        //DELETE THIS LINE
        // props.nextStep();

        if(typeof props.formData.email === "undefined" || !helpers.isValidEmail(props.formData.email)){
            helpers.errorPrompt("Please enter a valid email", "email", setErrorPrompt, setHighlighedtInput)
            return;
        }  else if(typeof props.formData.firstName === "undefined" || props.formData.firstName === ""){
            helpers.errorPrompt("Please enter a valid first name", "firstName", setErrorPrompt, setHighlighedtInput)
            return;
        }  else if(typeof props.formData.lastName === "undefined" || props.formData.lastName === ""){
            helpers.errorPrompt("Please enter a valid last name", "lastName", setErrorPrompt, setHighlighedtInput)
            return;
        } else if(typeof props.formData.password === "undefined" || props.formData.password.length < 6){
            helpers.errorPrompt("Please enter a valid password. Your password must be at least 6 characters long", "password", setErrorPrompt, setHighlighedtInput)
            return;
        } else if(typeof props.formData.businessName === "undefined" || props.formData.businessName === ""){
            helpers.errorPrompt("Please enter a valid business name", "businessName", setErrorPrompt, setHighlighedtInput)
            return;
        } else if(typeof props.formData.country === "undefined" || props.formData.country === ""){
            helpers.errorPrompt("Please enter a valid country", "country", setErrorPrompt, setHighlighedtInput)
            return;
        } else if(typeof props.formData.homePhone === "undefined" || props.formData.homePhone === ""){
            helpers.errorPrompt("Please enter a valid home phone", "homePhone", setErrorPrompt, setHighlighedtInput)
            return;
        } else if(typeof props.formData.mobilePhone === "undefined" || props.formData.mobilePhone === ""){
            helpers.errorPrompt("Please enter a valid mobile phone", "mobilePhone", setErrorPrompt, setHighlighedtInput)
            return;
        }
        props.nextStep();
        return;
    }

    return(
        <> 
            <div className={helpers.clx(styles.logoContainer, props.transitioningInto === 1 && props.transitioningFrom === 0 ? styles.logoContainerNextStep : "", props.transitioningInto === 0 && props.transitioningFrom === 1 ? styles.logoContainerPrevStep : "")}>
                <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
            </div>
            <div className={helpers.clx(styles.detailsContent, props.transitioning ? styles.detailsContentHidden : "")}>
                <h2 className={styles.detailsHeader}>
                    Your Personal Details
                </h2>
                <div className={styles.detailsRow}>
                    <TextInput name="E-Mail" value={props.formData.email} onChange={(value) => props.handleChange("email", value)} highlighted={highlighedtInput === "email" ? true : false} labelStyle="top" placeholder="Enter E-Mail"/>
                    <TextInput name="First Name" value={props.formData.firstName} onChange={(value) => props.handleChange("firstName", value)} highlighted={highlighedtInput === "firstName" ? true : false} labelStyle="top" placeholder="Enter First Name"/>
                </div>
                <div className={styles.detailsRow}>
                    <TextInput name="Password" isPassword={true} value={props.formData.password} onChange={(value) => props.handleChange("password", value)} highlighted={highlighedtInput === "password" ? true : false} labelStyle="top" placeholder="Enter Password"/>
                    <TextInput name="Last Name" value={props.formData.lastName} onChange={(value) => props.handleChange("lastName", value)} highlighted={highlighedtInput === "lastName" ? true : false} labelStyle="top" placeholder="Enter Last Name"/>
                </div>
                <div className={styles.detailsRow}>
                    <TextInput name="Business Name" value={props.formData.businessName} onChange={(value) => props.handleChange("businessName", value)} highlighted={highlighedtInput === "businessName" ? true : false} labelStyle="top" placeholder="Enter Business Name"/>
                    <Selector name="Country" value={props.formData.country} data={countries} onChange={(value) => props.handleChange("country", value)} highlighted={highlighedtInput === "country" ? true : false} labelStyle="top" placeholder="Select Country" color="white"/>
                </div>
                <div className={styles.detailsRow}>
                    <TextInput name="Home Phone" value={props.formData.homePhone} onChange={(value) => props.handleChange("homePhone", value)} highlighted={highlighedtInput === "homePhone" ? true : false} labelStyle="top" placeholder="Enter Home Phone"/>
                    <TextInput name="Mobile Phone" value={props.formData.mobilePhone} onChange={(value) => props.handleChange("mobilePhone", value)} highlighted={highlighedtInput === "mobilePhone" ? true : false} labelStyle="top" placeholder="Enter Mobile Phone"/>
                </div>
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={props.prevStep} name="Back" color="Error"/>
                    <Button disabled={false} onPress={handleSubmit} name="Next" color="Coal"/>
                </div>
                <div className={helpers.clx("errorPrompt", styles.errorPromptContainer)}>
                    {errorPrompt}
                </div>
            </div>
        </>
    )
}


export default React.memo(DetailsContent);