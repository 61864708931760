import { UserContext } from './UserContext.js';


import styles from './SupportScreen.module.css';
import helpers from './helpers.js';
import {useState, useContext} from 'react';


import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";
import FAQ from "./components/support/FAQ.js";





function SupportScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [errorPrompt, setErrorPrompt] = useState("");
  const [supportTicketSent, setSupportTicketSent] = useState(false);


  console.log(user)
  const [formData, setFormData] = useState({
    email: user.details.email,
    subject: "",
    message: ""
  });

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };

  const sendSupportTicket = async () => {
    if(formData.email === "" || formData.subject === "" || formData.message === ""){
        helpers.errorPrompt("Some fields are missing ...", "", setErrorPrompt, null)
        return;
    }
    const request = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          userId: user.details.id
        }),
      };
  
      let response = await fetch(user.backEndURL + "/support-ticket", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        setSupportTicketSent(true);
        console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");      
        return;
      } else {
        helpers.errorPrompt("Oops, something went wrong ... ", "", setErrorPrompt, null)
        console.log("SILENTLY FAILS, LIKE A NINJA")
        return;
      }  
}

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"support"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Support"} content={"Have some questions, or need some help?"}/>
          <div className={"contentGrid"}>
            <Module name={"Contact Form"} spanColumn={24} spanRow={1} height={"auto"}>
              <div className={styles.contactFormText}>
                Having issues? Check our our FAQ below, and feel free to send us a message via our contact form if you still have questions. You can also reach our support team directly be email at <a href = "mailto:support@blackbullbiochar.com">support@blackbullbiochar.com</a>, or by phone at <b>+44 74 458 48921</b>.
              </div>
              <div className={styles.contactFormRow}>
                <div className={styles.inputEmail}>
                  <TextInput name={"E-mail"} value={formData.email} onChange={(value) => handleChange("email", value)} placeholder={"Enter Email Address"} labelStyle={"top"} />
                </div>
                <div className={styles.inputSubject}>
                  <TextInput name={"Subject"} value={formData.subject} onChange={(value) => handleChange("subject", value)} placeholder={"Enter Subject"} labelStyle={"top"} />
                </div>
              </div>
              <TextInput name={"Message"} value={formData.message} onChange={(value) => handleChange("message", value)} placeholder={"Enter Your Message"} labelStyle={"top"} height={"15rem"}/>
              <div className={styles.contactFormButtonRow}>
              <div className={helpers.clx("errorPrompt", styles.errorPromptContainer)}>
                    {errorPrompt}
                </div>
                <div className={styles.contactFormButtonWrapper}>
                  {
                    !supportTicketSent ? 
                      <Button name={"Send Message"} color={"Coal"} onPress={sendSupportTicket}/>
                      :
                      <div className={styles.messageSent}>Message was successfully sent!</div>
                  }
                </div>
              </div>
            </Module>
            <Module name={"Frequently Asked Questions"} spanColumn={24} spanRow={1} height={"auto"}>
              <div className={styles.contactFormText}>
                Go through the most frequently asked questions, you might already find the answer to the problem you’re having.
              </div>
              <FAQ/>
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportScreen;
