import React, {useMemo, useState} from 'react';
import styles from './SuccessContent.module.css'; //styles
import helpers from '../../helpers.js';

import Button from "../Button.js";
import Checkbox from "../Checkbox.js";


import bbbLogo from "../../assets/images/bbbLogo.png";
import countries from "../../assets/Countries.js";

const SuccessContent = (props) => {

    return(
        <> 
            <div className={helpers.clx(styles.logoContainer)}>
                <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
            </div>
            <div className={helpers.clx(styles.successContent, props.transitioning ? styles.successContentHidden : "")}>
                <h2 className={styles.successHeader}>
                    You're All Set !
                </h2>
                <p className={styles.successText}>Your account was successfully created. You may now login to the BBB app using your credentials.</p>
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={props.nextStep} name="Back To Login" color="Coal" customStyle={{margin: "0.5rem"}}/>
                </div>
            </div>
        </>
    )
}


export default React.memo(SuccessContent);