import styles from './Overlay.module.css'; //styles
import helpers from '../helpers.js';
import { useCallback } from 'react';


/*
    Overlay
        props:
            spanRow: grid row height (1 = 100px)
            spanColumn: grid column width (/24 eg: 6 takes up a quarter of the screen) 
*/
const Overlay = (props) => {
    return(
        <div className={styles.overlayBg}>
            <div className={styles.overlay}>
                {props.children}
            </div>
        </div>
    )
}

// Overlay.defaultProps = {
//     height: "100%",
//     background: "",
//     icon: "",
//     name: ""
//   };

export default Overlay;