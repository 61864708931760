
import React, {useState, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { UserContext } from '../../UserContext.js';

import styles from './ReviewSubscriptionScreen.module.css';
import helpers from '../../helpers.js';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import Button from "../Button.js";
import TermsAndConditions from "./TermsAndConditions.js";


//TODO ROUNDING
//ADDRESS LINE 2?? BUSINESSNAME

function ReviewSubscriptionScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [formData, setFormData] = useState(useLocation().state.formData);
  const [deliveryDetails, setDeliveryDetails] = useState(useLocation().state.addresses.deliveryDetails);
  const [billingDetails, setBillingDetails] = useState(useLocation().state.addresses.billingDetails);
  const [addresses, setAddresses] = useState(useLocation().state.addresses);

  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleSubmit = async () => {
    if(true){ //FORM VALIDATION HERE
        const request = {
            method: "put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(formData),
          };
      
          let response = await fetch(user.backEndURL + "/update-subscription", request);
          let jsonResponse = await response.json();
          if (jsonResponse.success) {
            props.goToScreen("confirm-subscription")
          }else {
            // helpers.errorPrompt("Oops something went wrong while updating...", null, setPrompt, null)
          }
    } else {
        // helpers.errorPrompt("Passwords do not match!", "password2", setPrompt, setHighlighedtInput)
    }
    return;
  };


  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
      <div className={helpers.clx(styles.overlayContainer, displayOverlay ? styles.overlayContainerDisplay : "")}>
            <TermsAndConditions handleSubmit={handleSubmit} setDisplayOverlay={setDisplayOverlay}/>
          </div>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Package"} content={"Set up your Black Bull Biochar subscription and start getting biochar delivered straight to your farm! Subscriptions are made on a yearly basis, and deliveries are made each quarter. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module name={"Review Package"} spanColumn={24} spanRow={1} height={"100%"}>
              <div className={styles.reviewDescription}>
              Please review the details of your package to make sure the information is accurate and up to date. 
              </div>
              <div className={styles.contentContainer}>
                <div className={styles.column}>
                    <div className={styles.reviewModule}>
                      <div className={styles.moduleHeader}>
                        Package Overview
                      </div>
                      <div className={styles.overviewRow}>
                        <div className={styles.contentSection}>
                          <div className={styles.contentHeader}>
                            Pinchar<br/>
                          </div>
                          <div className={styles.contentRow}>
                            <span className={styles.icon}>shopping-bag</span> {formData.pincharBags} 
                          </div>
                          <div className={styles.contentRow}>
                            <span className={styles.icon}>cube</span> {helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).volumePinchar} m<span className={styles.exp}>3</span> 
                          </div>
                          <div className={styles.contentRow}>
                            <div className={styles.icon}>balance-scale</div> {helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).weightPinchar} t 
                          </div>
                        </div>
                        <div style={{opacity: 0.4}} className={styles.contentSection}>
                          <div className={styles.contentHeader}>
                            Pelletchar<br/>
                          </div>
                          <div className={styles.contentRow}>
                            <span className={styles.icon}>shopping-bag</span> {formData.pelletcharBags} 
                          </div>
                          <div className={styles.contentRow}>
                            <span className={styles.icon}>cube</span> {Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).volumePelletchar)} m<span className={styles.exp}>3</span> 
                          </div>
                          <div className={styles.contentRow}>
                            <div className={styles.icon}>balance-scale</div> {Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).weightPelletchar)} t 
                          </div>
                        </div>
                        <div className={styles.contentSection}>
                          <div className={styles.contentHeader}>
                            Yearly Price<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentBold)}>
                            £ 0{/*helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).priceTotal*/}
                          </div>
                        </div>
                        <div className={styles.contentSection}>
                          <div className={styles.contentHeader}>
                            Billing<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentBold)}>
                            £ 0{/*Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, formData.billingPlan).priceTotal / 12)*/} / month
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.reviewModule}>
                      <div className={styles.moduleHeader}>
                        Delivery Details
                      </div>
                      <div className={styles.overviewRow}>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Contact<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            {addresses.deliveryDetails.firstName} {addresses.deliveryDetails.lastName}<br/>
                            {addresses.deliveryDetails.email}<br/>
                            {addresses.deliveryDetails.phone}
                          </div>
                        </div>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Delivery Address<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                          {addresses.deliveryDetails.firstName} {addresses.deliveryDetails.lastName}<br/>
                          {addresses.deliveryDetails.addressLine1}<br/>
                          {addresses.deliveryDetails.county}<br/>
                          {addresses.deliveryDetails.zip}
                          </div>
                        </div>
                      </div>
                      <div className={styles.overviewRow}>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery2)}>
                          <div className={styles.contentHeader}>
                            Delivery Instructions<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                          {addresses.deliveryDetails.instructions}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className={styles.column}>
                <div className={styles.reviewModule}>
                      <div className={styles.moduleHeader}>
                        Scheduled Deliveries
                      </div>
                      <div className={styles.overviewRow}>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Delivery 1<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Week of: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>TBC</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pinchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>TBC</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pelletchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pelletcharBags/4)} bags</span><br/>
                          </div>
                        </div>
                        {/* <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Delivery 2<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Week of: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>17/10/22</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pinchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pincharBags/4)} bags</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pelletchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pelletcharBags/4)} bags</span><br/>
                          </div>
                        </div> */}
                      </div>
                      <div className={styles.overviewRow}>
                      <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          {/* <div className={styles.contentHeader}>
                            Delivery 3<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Week of: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>02/02/23</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pinchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pincharBags/4)} bags</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pelletchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pelletcharBags/4)} bags</span><br/>
                          </div>
                        </div>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Delivery 4<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Week of: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>11/07/23</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pinchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pincharBags/4)} bags</span><br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            Pelletchar: <span className={helpers.clx(styles.contentBold, styles.contentBoldDeliveries)}>{Math.round(formData.pelletcharBags/4)} bags</span><br/>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className={styles.reviewModule}>
                      <div className={styles.moduleHeader}>
                        Billing Details
                      </div>
                      <div className={styles.overviewRow}>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Contact<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            {addresses.billingDetails.firstName} {addresses.billingDetails.lastName}<br/>
                            {addresses.billingDetails.email}<br/>
                            {addresses.billingDetails.phone}
                          </div>
                        </div>
                        <div className={helpers.clx(styles.contentSection, styles.contentDelivery1)}>
                          <div className={styles.contentHeader}>
                            Billing Address<br/>
                          </div>
                          <div className={helpers.clx(styles.contentRow, styles.contentRegular)}>
                            {addresses.billingDetails.businessName}<br/>
                            {addresses.billingDetails.addressLine1}<br/>
                            {addresses.billingDetails.county}<br/>
                            {addresses.billingDetails.zip}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
              <div className={styles.buttonRow}>
                <Button onPress={() => props.goToScreen("update-subscription", {formData: formData, addresses: addresses})} name="Back" color="Error"/>
                <Button onPress={() => setDisplayOverlay(true)} name="Confirm" color="Grass"/>
              </div>
              
            </Module>

          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ReviewSubscriptionScreen);
