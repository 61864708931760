import styles from './Slider.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';



/*
    CHECKBOX
        props:
            checked: boolean, sets checkbox status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before checkbox 
*/
const Slider = (props) => {

    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    const renderSliderName = () => {
        switch (props.name) {
            case "beddingArea":
                return (<>Bedding Area (m<span className={styles.areaExp}>2</span>) :</>)
            case "beddingPeriod":
                return (<>Bedding Period (weeks) :</>)
                break;
            case "manureQty":
                return (<>Manure Quantity (tons) :</>)
                break;
            case "slurryQty":
                return (<>Slurry Production Qty (gallons) :</>)
                break;
            
        }
    }

    return(
        <div className={styles.sliderContainer}>  
            <div className={styles.topLabelRow}>
                <div className={styles.sliderName}>
                    {renderSliderName()}
                </div>
                <input type="text" value={props.value} onChange={(e) => props.handleChange(props.name, e.target.value)} className={styles.sliderInput}/>
            </div>

            <input type="range" value={props.value} onChange={(e) => props.handleChange(props.name, e.target.value)} min={props.minRange} max={props.maxRange}/>
            <div className={styles.rangesRow}>
                <div className={styles.rangeLabel}>
                    {props.minRange}
                </div>
                <div className={styles.rangeLabel}>
                    {props.maxRange}
                </div>
            </div>
        </div>
    )
}

Slider.defaultProps = {
    value: 0,
  };

export default Slider;