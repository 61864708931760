import styles from './TermsAndConditions.module.css'; //styles
// import helpers from '../helpers.js';
import Button from "../Button.js"
import React, {useState, useEffect } from 'react';

import bbbLogo from '../../assets/images/bbbLogo.png';



/*
    CHECKBOX
        props:
          
*/
const TermsAndConditions = (props) => {
    return(
        <div className={styles.termsAndConditionsContainer}>
            <div className={styles.divBlock217}>
                <div className={styles.divBlock218}>
                    <div className={styles.divBlock79}>Terms &amp;&nbsp;Conditions</div>
                    <div>Please read and review our terms and conditions</div>
                    <div className={styles.divBlock80}>Version:&nbsp;September.2023.1</div>
                </div>
                <img src="https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte.png" loading="lazy" sizes="(max-width: 1279px) 40.0625px, (max-width: 1439px) 3vw, 40.0625px" srcset="https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte-p-500.png 500w, https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte-p-800.png 800w, https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte-p-1080.png 1080w, https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte-p-1600.png 1600w, https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte-p-2000.png 2000w, https://assets-global.website-files.com/612de9d6e2e1a211ef704a8c/613b3ac57a9abc84a37c5f00_logo%20sans%20texte.png 2345w" alt="Black Bull Biochar Logo" class="image-145"/>
            </div>
            <div className={styles.termsAndConditionsRTE}>
            <p><strong>The Customer's attention is drawn in particular to the provisions of clause 10.</strong></p>
            <h3><strong>‍</strong>1. Interpretation</h3>
            <p><strong>1.1 Definitions:</strong></p>
            <p><strong>BBB</strong>: Black Bull Biochar Ltd (registered in England and Wales with company number 13772184) whose registered office is at 5/7 Tanner Street, Bermondsey, London, England, SE1 3LE.</p>
            <p>‍<strong>BBB’s Farmer Handbook</strong>: means the guidance document provided by BBB to the Customer, as may be updated, amended or replaced from time to time.</p>
            <p>‍<strong>Business Day</strong>: a day other than a Saturday, Sunday or public holiday in England, when banks in London are open for business.</p>
            <p>‍<strong>Business Hours</strong>: the period from 9.00 am to 5.30 pm on any Business Day.</p>
            <p>‍<strong>Conditions</strong>: the terms and conditions set out in this document as amended from time to time in accordance with clause 14.4.</p>
            <p>‍<strong>Contract</strong>: the contract between BBB and the Customer for the sale and purchase of the Goods in accordance with these Conditions.</p>
            <p>‍<strong>Customer</strong>: the person or firm who purchases the Goods from BBB. Delivery Location: has the meaning given in clause 4.2.</p>
            <p>‍<strong>Force Majeure Event</strong>: an event, circumstance or cause beyond a party's reasonable control.</p>
            <p>‍<strong>Goods</strong>: the goods (or any part of them) set out in the Order.</p>
            <p>‍<strong>Order</strong>: the Customer's order for the Goods, as set out in the Customer's purchase order form, the Customer's written acceptance of BBB's quotation, or overleaf, as the case may be.</p>
            <p>‍<strong>Specification</strong>: any specification for the Goods, including any related plans and drawings, that is agreed by the Customer and BBB.</p>
            <p><strong>1.2 Interpretation:</strong></p>
            <ul role="list">
                <li class="list-item-2">(a) A reference to legislation or a legislative provision is a reference to it as amended or re-enacted. A reference to legislation or a legislative provision includes all subordinate legislation made under that legislation or legislative provision.</li>
                <li class="list-item-2">(b) Any words following the terms <strong>including</strong>, <strong>include</strong>, <strong>in particular</strong>, <strong>for example</strong> or any similar expression shall be interpreted as illustrative and shall not limit the sense of the words preceding those terms.</li>
                <li class="list-item-2">(c) A reference to <strong>writing</strong> or <strong>written</strong> excludes fax but not email.</li>
            </ul>
            <h3>2. Basis of contract</h3>
            <p><strong>2.1</strong> These Conditions apply to the Contract to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing.</p>
            <p><strong>2.2</strong> The Order constitutes an offer by the Customer to purchase the Goods in accordance with these Conditions. The Customer is responsible for ensuring that the terms of the Order are complete and accurate.</p>
            <p><strong>2.3</strong> The Order shall only be deemed to be accepted when BBB issues a written acceptance of the Order, at which point the Contract shall come into existence.</p>
            <p><strong>2.4</strong> The Customer waives any right it might otherwise have to rely on any term endorsed upon, delivered with or contained in any documents of the Customer that is inconsistent with these Conditions.</p>
            <p><strong>2.5</strong> Any samples, drawings, descriptive matter or advertising produced by BBB and any descriptions or illustrations contained in BBB's catalogues or brochures are produced for the sole purpose of giving an approximate idea of the Goods referred to in them. They shall not form part of the Contract nor have any contractual force.</p>
            <p><strong>2.6</strong> A quotation for the Goods given by BBB shall not constitute an offer. A quotation shall only be valid for a period of 20 Business Days from its date of issue.</p>
            <h3>3. Goods</h3>
            <p><strong>3.1</strong> The Goods are described in the Specification.</p>
            <p><strong>3.2</strong> BBB reserves the right to amend the Specification if required by any applicable statutory or regulatory requirement, and BBB shall notify the Customer in any such event.</p>
            <h3>4. Delivery</h3>
            <p><strong>4.1</strong> BBB shall ensure that:</p>
            <ul role="list">
                <li>(a) each delivery of the Goods is accompanied by a delivery note that shows the date of the Order, relevant BBB reference number(s), the type and quantity of the Goods, special storage instructions (if any) and, if the Goods are being delivered by instalments, the outstanding balance of Goods remaining to be delivered; and </li>
                <li>(b) if BBB requires the Customer to return any packaging materials to BBB, that fact is clearly stated on the delivery note. The Customer shall make any such packaging materials available for collection at such times as BBB shall reasonably request. Returns of packaging materials shall be at BBB's expense. </li>
            </ul>
            <p><strong>4.2</strong> BBB shall deliver the Goods to the location set out in the Order or such other location as the parties may agree (the “Delivery Location”) at any time after BBB notifies the Customer that the Goods are ready. </p>
            <p><strong>4.3</strong> Delivery is completed when the vehicle transporting the Goods arrives as the designated Delivery Location. The Customer acknowledges and agrees that the Customer shall be responsible for unloading the Goods from the vehicle at the Delivery Location. </p>
            <p><strong>4.4</strong> Any dates quoted for delivery are approximate only, and the time of delivery is not of the essence. BBB shall not be liable for any delay in delivery of the Goods that is caused by a Force Majeure Event or the Customer's failure to provide BBB with adequate delivery instructions or any other instructions that are relevant to the supply of the Goods. </p>
            <p><strong>4.5</strong> BBB shall have no liability for any failure to deliver the Goods to the extent that such failure is caused by a Force Majeure Event or the Customer's failure to provide BBB with adequate delivery instructions or any other instructions that are relevant to the supply of the Goods. </p>
            <p><strong>4.6</strong> If the Customer fails to take delivery of the Goods, then, except where such failure or delay is caused by a Force Majeure Event or BBB's failure to comply with its obligations under the Contract in respect of the Goods: </p>
            <ul role="list">
                <li class="list-item-2">(a) delivery of the Goods shall be deemed to have been completed on the day on which BBB scheduled the delivery and/or arrived at the Delivery Notification; </li>
                <li class="list-item-2">(b) BBB shall store the Goods until actual delivery takes place, and charge the Customer for all related costs and expenses (including insurance); </li>
                <li class="list-item-2">(c) the Customer shall, on a full indemnity basis, be liable and pay BBB for all subsequent transportation costs incurred by BBB as a result of the Customer failing to take delivery of the Goods; and </li>
                <li class="list-item-2">(d) BBB may resell or otherwise dispose of part or all of the Goods and, after deducting reasonable storage and selling costs, account to the Customer for any excess over the price of the Goods or charge the Customer for any shortfall below the price of the Goods. </li>
            </ul>
            <p><strong>4.7</strong> BBB may deliver the Goods by instalments, which may be invoiced and paid for separately. Each instalment shall constitute a separate contract. Any delay in delivery or defect in an instalment shall not entitle the Customer to cancel any other instalment. </p>
            <h3>5. Quality </h3>
            <p><strong>5.1</strong> BBB warrants that on delivery the Goods shall conform in all material respects with the Specification. </p>
            <p><strong>5.2</strong> Subject to clause 5.3, if: </p>
            <ul role="list">
                <li class="list-item-2">(a) the Customer gives notice in writing to BBB within a reasonable time of discovery that some or all of the Goods do not comply with the warranty set out in clause 5.1; </li>
                <li class="list-item-2">(b) BBB is given a reasonable opportunity of examining such Goods; and </li>
                <li class="list-item-2">(c) the Customer (if asked to do so by BBB) returns such Goods to BBB's place of business at the Customer's cost, BBB shall, at its option, replace the defective Goods, or refund the price of the defective Goods in full. </li>
            </ul>
            <p><strong>5.3</strong> BBB shall not be liable for the Goods' failure to comply with the warranty set out in clause 5.1 if: </p>
            <ul role="list">
                <li class="list-item-2">(a) the Customer makes any further use of such Goods after giving notice in accordance with clause 5.2; </li>
                <li class="list-item-2">(b) the defect arises because the Customer failed to follow BBB's oral or written instructions as to the storage, and use of the Goods or (if there are none) good trade practice regarding the same; </li>
                <li class="list-item-2">(c) the Customer alters such Goods without the written consent of BBB; </li>
                <li class="list-item-2">(d) the defect arises as a result of wilful damage, negligence, or abnormal storage or working conditions; or </li>
                <li class="list-item-2">(e) the Goods differ from the Specification as a result of changes made to ensure they comply with applicable statutory or regulatory requirements. </li>
            </ul>
            <p><strong>5.4</strong> Except as provided in this clause 5, BBB shall have no liability to the Customer in respect of the Goods' failure to comply with the warranty set out in clause 5.1. </p>
            <p><strong>5.5</strong> The terms implied by sections 13 to 15 of the Sale of Goods Act 1979 are, to the fullest extent permitted by law, excluded from the Contract. </p>
            <p><strong>5.6</strong> These Conditions shall apply to any replacement Goods supplied by BBB. </p>
            <h3>6. Use of Goods </h3>
            <p><strong>6.1</strong> The Customer acknowledges and agrees that: </p>
            <ul role="list">
                <li class="list-item-2">(a) The Customer shall use the Goods in accordance with BBB’s Farmer Handbook (as may be updated by BBB from time to time). </li>
                <li class="list-item-2">(b) The Goods shall only be used for slurry management systems, bedding systems, or application directly to land. </li>
                <li class="list-item-2">(c) The Goods shall only be used in accordance with applicable laws and government (and government departmental) guidance, including without prejudice to the generality of the foregoing the “Storing and spreading biochar to benefit land: LRWP 61” guidance. </li>
            </ul>
            <p><strong>6.2</strong> The Customer acknowledges and agrees that the Goods supplied are for the Customer’s use only and they shall not re-sell (or seek to re-sell) the Goods to a third party. </p>
            <h3>7. Title and risk </h3>
            <p><strong>7.1</strong> The risk in the Goods shall pass to the Customer on completion of delivery. </p>
            <p><strong>7.2</strong> Title to the Goods shall not pass to the Customer until BBB receives payment in full (in cash or cleared funds) for the Goods. Notwithstanding the foregoing, if a delivery of Goods is invoiced at a zero rate, title to such Goods shall pass to the Customer on completion of delivery. </p>
            <p><strong>7.3</strong> Until title to the Goods has passed to the Customer, the Customer shall: </p>
            <ul role="list">
                <li class="list-item-2">(a) store the Goods separately from all other goods held by the Customer so that they remain readily identifiable as BBB's property; </li>
                <li class="list-item-2">(b) not remove, deface or obscure any identifying mark or packaging on or relating to the Goods; </li>
                <li class="list-item-2">(c) maintain the Goods in satisfactory condition and keep them insured against all risks for their full price from the date of delivery; </li>
                <li class="list-item-2">(d) notify BBB immediately if it becomes subject to any of the events listed in clause 11.1(b) to clause 11.1(d);</li>
                <li class="list-item-2">(e) give BBB such information as BBB may reasonably require from time to time relating to: (i) the Goods; and (ii) the ongoing financial position of the Customer. </li>
            </ul>
            <p><strong>7.4</strong> At any time before title to the Goods passes to the Customer, BBB may require the Customer to deliver up all Goods in its possession that have not been used and if the Customer fails to do so promptly, enter any premises of the Customer or of any third party where the Goods are stored in order to recover them. </p>
            <h3>8. Price and payment </h3>
            <p><strong>8.1</strong> The price of the Goods shall be the price set out in the Order, or, if no price is quoted, the price set out in BBB's published price list in force as at the date of delivery. </p>
            <p><strong>8.2</strong> BBB may, by giving notice to the Customer at any time before delivery, increase the price of the Goods to reflect any increase in the cost of the Goods that is due to: </p>
            <ul role="list">
                <li class="list-item-2">(a) any factor beyond BBB's control (including foreign exchange fluctuations, increases in taxes and duties, and increases in labour, materials and other manufacturing costs); </li>
                <li class="list-item-2">(b) any request by the Customer to change the delivery date(s), quantities or types of Goods ordered; or </li>
                <li class="list-item-2">(c) any delay caused by any instructions of the Customer or failure of the Customer to give BBB adequate or accurate information or instructions. </li>
            </ul>
            <p><strong>8.3 </strong>The price of the Goods: </p>
            <ul role="list">
                <li class="list-item-2">(a) excludes amounts in respect of value added tax (VAT), which the Customer shall additionally be liable to pay to BBB at the prevailing rate, subject to the receipt of a valid VAT invoice; and </li>
                <li class="list-item-2">(b) excludes the costs and charges of packaging, insurance and transport of the Goods, which shall be invoiced to the Customer. </li>
            </ul>
            <p><strong>8.4</strong> BBB may invoice the Customer for the Goods on or at any time after the completion of delivery. </p>
            <p><strong>8.5</strong> The Customer shall pay each invoice submitted by BBB: </p>
            <ul role="list">
                <li class="list-item-2">(a) within 30 days of the date of the invoice or in accordance with any credit terms agreed by BBB and confirmed in writing to the Customer; and </li>
                <li class="list-item-2">(b) in full and in cleared funds to a bank account nominated in writing by BBB. </li>
            </ul>
            <p><strong>8.6</strong> The parties acknowledge and agree that time for payment shall be of the essence of the Contract. </p>
            <p><strong>8.7</strong> If the Customer fails to make a payment due to BBB under the Contract by the due date, then, without limiting BBB's remedies under clause 11, the Customer shall pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgment. Interest under this clause 8.7 will accrue each day at 4% a year above the Bank of England's base rate from time to time, but at 4% a year for any period when that base rate is below 0%. </p>
            <p><strong>8.8</strong> All amounts due under the Contract shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law). </p>
            <h3>9. Collaboration </h3>
            <p><strong>9.1</strong> If requested by BBB, the Customer shall: </p>
            <ul role="list">
                <li class="list-item-2">(a) Provide feedback on the Goods and the Customer’s use of the Goods; </li>
                <li class="list-item-2">(b) Meet once per quarter (either in person or by telephone or video conference) to discuss the Goods and the working relationship between BBB and the Customer. </li>
            </ul>
            <h3>10. Limitation of liability </h3>
            <p><strong>10.1</strong> References to liability in this clause 10 include every kind of liability arising under or in connection with the Contract including liability in contract, tort (including negligence), misrepresentation, restitution or otherwise. </p>
            <p><strong>10.2</strong> Nothing in the Contract limits any liability which cannot legally be limited, including liability for: </p>
            <ul role="list">
                <li class="list-item-2">(a) death or personal injury caused by negligence; </li>
                <li class="list-item-2">(b) fraud or fraudulent misrepresentation; or </li>
                <li class="list-item-2">(c) breach of the terms implied by section 12 of the Sale of Goods Act 1979. </li>
            </ul>
            <p><strong>10.3</strong> Subject to clauses 10.2 and 10.4: </p>
            <ul role="list">
                <li class="list-item-2">(a) BBB's total liability to the Customer in relation to any liability arising out of an Order shall not exceed the price stated in the applicable Order; and </li>
                <li class="list-item-2">(b) For any other liability, BBB’s total liability to the Customer shall not exceed the amount paid by the Customer to BBB in relation to Goods supplied in the previous 12 months. </li>
            </ul>
            <p><strong>10.4</strong> Subject to clause 10.2, the following types of loss are wholly excluded: </p>
            <ul role="list">
                <li class="list-item-2">(a) loss of profits; </li>
                <li class="list-item-2">(b) loss of sales or business; </li>
                <li class="list-item-2">(c) loss of agreements or contracts; </li>
                <li class="list-item-2">(d) loss of anticipated savings; </li>
                <li class="list-item-2">(e) loss of use or corruption of software, data or information; </li>
                <li class="list-item-2">(f) loss of or damage to goodwill; and </li>
                <li class="list-item-2">(g) indirect or consequential loss. </li>
            </ul>
            <p><strong>10.5</strong> This clause 10 shall survive termination of the Contract. </p>
            <h3>11. Termination </h3>
            <p><strong>11.1</strong> Without limiting its other rights or remedies, BBB may terminate this Contract with immediate effect by giving written notice to the Customer if: </p>
            <ul role="list">
                <li class="list-item-2">(a) the Customer commits a material breach of any term of the Contract and (if such a breach is remediable) fails to remedy that breach within 30 days of that party being notified in writing to do so; </li>
                <li class="list-item-2">(b) the Customer takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), obtaining a moratorium, being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business; </li>
                <li class="list-item-2">(c) the Customer suspends, threatens to suspend, ceases or threatens to cease to carry on all or a substantial part of its business; or </li>
                <li class="list-item-2">(d) the Customer's financial position deteriorates so far as to reasonably justify the opinion that its ability to give effect to the terms of the Contract is in jeopardy. </li>
            </ul>
            <p><strong>11.2</strong> Without limiting its other rights or remedies, BBB may suspend provision of the Goods under the Contract or any other contract between the Customer and BBB if the Customer becomes subject to any of the events listed in clause 11.1(b) to clause 11.1(d), or BBB reasonably believes that the Customer is about to become subject to any of them, or if the Customer fails to pay any amount due under this Contract on the due date for payment. </p>
            <p><strong>11.3</strong> Without limiting its other rights or remedies, BBB may terminate the Contract with immediate effect by giving written notice to the Customer if the Customer fails to pay any amount due under the Contract on the due date for payment. </p>
            <p><strong>11.4</strong> On termination of the Contract for any reason the Customer shall immediately pay to BBB all of BBB's outstanding unpaid invoices and interest and, in respect of Goods supplied but for which no invoice has been submitted, BBB shall submit an invoice, which shall be payable by the Customer immediately on receipt. </p>
            <p><strong>11.5</strong> Termination of the Contract, however arising, shall not affect any of the parties' rights and remedies that have accrued as at termination, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination. </p>
            <p><strong>11.6</strong> Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination of the Contract shall remain in full force and effect. </p>
            <h3>12. Force majeure </h3>
            <p>Neither party shall be in breach of the Contract or otherwise liable for any failure or delay in the performance of its obligations if such delay or failure results from a Force Majeure Event. The time for performance of such obligations shall be extended accordingly. If the period of delay or non-performance continues for 4 weeks, the party not affected may terminate the Contract by giving 30 days' written notice to the affected party. </p>
            <h3>13. Data Protection </h3>
            <p><strong>13.1</strong> Each party shall, at its own expense, ensure that it complies with and assists the other party to comply with the requirements of all legislation and regulatory requirements in force from time to time in the UK relating to the use of personal data and the privacy of electronic communications, including </p>
            <ul role="list">
                <li class="list-item-2">(i) the Data Protection Act 2018 and any successor UK legislation, </li>
                <li class="list-item-2">(ii) the retained EU law version of General Data Protection Regulation ((EU) 2016/679) (UK GDPR), and </li>
                <li class="list-item-2">(iii) the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) and the guidance and codes of practice issued by the Information Commissioner or other relevant regulatory authority and applicable to a party. </li>
            </ul>
            <h3>14. General </h3>
            <p><strong>14.1</strong> Assignment and other dealings. </p>
            <ul role="list">
                <li class="list-item-2">(a) BBB may at any time assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with all or any of its rights or obligations under the Contract. </li>
                <li class="list-item-2">(b) The Customer may not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights or obligations under the Contract without the prior written consent of BBB. </li>
            </ul>
            <p><strong>14.2</strong> Confidentiality. </p>
            <ul role="list">
                <li class="list-item-2">(a) Each party undertakes that it shall not at any time during the Contract and for a period of 2 years after termination of the Contract, disclose to any person any confidential information concerning the business, assets, affairs, customers, clients or BBBs of the other party, except as permitted by clause 14.2(b). </li>
                <li class="list-item-2">(b) Each party may disclose the other party's confidential information: </li>
                <li class="list-item-3">(i) to its employees, officers, representatives, contractors, subcontractors or advisers who need to know such information for the purposes of exercising the party's rights or carrying out its obligations under the Contract. Each party shall ensure that its employees, officers, representatives, contractors, subcontractors or advisers to whom it discloses the other party's confidential information comply with this clause 14.2; and </li>
                <li class="list-item-3">(ii) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority. </li>
                <li class="list-item-2">(c) Neither party shall use the other party's confidential information for any purpose other than to exercise its rights and perform its obligations under or in connection with the Contract. </li>
                <li class="list-item-2">(d) Notwithstanding anything to the contrary in the Contract, the parties acknowledge and agree </li>
            </ul>
            <p><strong>14.3</strong> Entire agreement. </p>
            <ul role="list">
                <li class="list-item-2">(a) The Contract constitutes the entire agreement between the parties. </li>
                <li class="list-item-2">(b) Each party acknowledges that in entering into the Contract it does not rely on any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Contract. Each party agrees that it shall have no claim for innocent or negligent misrepresentation based on any statement in the Contract. </li>
            </ul>
            <p><strong>14.4</strong> Variation. No variation of this Contract shall be effective unless it is in writing and signed by the parties (or their authorised representatives). </p>
            <p><strong>14.5</strong> Waiver. </p>
            <ul role="list">
                <li class="list-item-2">(a) Except as set out in clause 2.4, a waiver of any right or remedy is only effective if given in writing. </li>
                <li class="list-item-2">(b) A delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. </li>
            </ul>
            <p><strong>14.6</strong> Severance. If any provision or part-provision of the Contract is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of the Contract. If any provision of the Contract is deemed deleted under this clause 14.6 the parties shall negotiate in good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended commercial result of the original provision. </p>
            <p><strong>14.7</strong> Notices. </p>
            <ul role="list">
                <li class="list-item-2">(a) Any notice given to a party under or in connection with the Contract shall be in writing and shall be: </li>
                <li class="list-item-3">(i) delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business </li>
                <li class="list-item-3">(ii) sent by email to the following addresses (or an address substituted in writing by the party to be served): BBB: accounts@blackbullbiochar.com. Customer: To the email address specified by the Customer to BBB for this purpose. </li>
                <li class="list-item-2">(b) Any notice shall be deemed to have been received: </li>
                <li class="list-item-3">(i) if delivered by hand, at the time the notice is left at the proper address; </li>
                <li class="list-item-3">(ii) if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second Business Day after posting; or </li>
                <li class="list-item-3">(iii) if sent by email, at the time of transmission, or, if this time falls outside Business Hours in the place of receipt, when Business Hours resume.</li>
                <li class="list-item-2">(c) This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any arbitration or other method of dispute resolution. </li>
            </ul>
            <p><strong>14.8</strong> Third party rights. The Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract. </p>
            <p><strong>14.9</strong> Governing law. The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation, shall be governed by and construed in accordance with the law of England and Wales. </p>
            <p><strong>14.10</strong> Jurisdiction. Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.</p>
            </div>
            <div className={styles.buttonRow}>
                <Button onPress={() => props.setDisplayOverlay(false)} name="Decline" color="Error" nameSelected="Decline"/>
                <Button onPress={() => props.handleSubmit()} name="Accept" color="Grass" />
            </div>
        </div>
    )
}

export default React.memo(TermsAndConditions);
