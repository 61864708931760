
import React, {useState } from 'react';

import styles from './ConfirmationScreen.module.css';
import helpers from '../../helpers.js';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import Button from "../Button.js";
import TermsAndConditions from "./TermsAndConditions.js";

import check from "../../assets/images/check.png";




function ConfirmationScreen(props) {
  const [formData, setFormData] = useState({
    pincharBags: 20,
    pelletcharBags: 9,
    billingPlan: "monthly"
  });
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Subscription"} content={"Set up your Black Bull Biochar subscription and start getting biochar delivered straight to your farm! Subscriptions are made on a yearly basis, and deliveries are made each quarter. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module spanColumn={24} spanRow={1} height={"74vh"}>
              <div className={styles.confirmationContent}>
                <img src={check} className={styles.checkIcon}/>
                <div className={styles.confirmationHeader}>
                  Subscription Successfully Registered!
                </div>
                <div className={styles.confirmationText}>
                Thank you for using Black Bull Biochar! You will shortly receive a confirmation email with the all the relevant details of your subscription, orders, and invoices. If you have any questions, don’t hesitate to reach us at <b>help@blackbullbiochar.com</b>
                </div>
                <div className={styles.buttonRow}>
                  <Button name="Back" onPress={() => props.goToScreen("subscription")} color="Coal"/>
                </div>
              </div>
            </Module>

          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ConfirmationScreen);
