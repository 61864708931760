import { useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";

import styles from './UpdateSubscriptionScreen.module.css';
import helpers from '../../helpers.js';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import Button from "../Button.js"
import PaymentPlans from "./PaymentPlans.js";
import ProductCounter from "./ProductCounter.js";
import Calculator from "./Calculator.js";

import pincharPlaceholder from "../../assets/images/pincharPlaceholder.png"


function UpdateSubscriptionScreen(props) {
  let isFromNextScreen = typeof useLocation().state.formData !== "undefined";
  let nextUseLocationFormData = useLocation().state.formData;

  const [addresses, setAddresses] = useState(useLocation().state.addresses);
  
  const [formData, setFormData] = useState(
    typeof useLocation().state.formData === "undefined" ? 
    {
      pincharBags: 0,
      pelletcharBags: 0,
      paymentPlan: ""
    }
    : 
    nextUseLocationFormData
  );

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };
  
  const updateBiocharQty = (pincharBags, pelletcharBags) => {
    setFormData({ ...formData, pincharBags: pincharBags, pelletcharBags: 0 });
  };

  const calculatePlanDiscount = () => {
    switch(formData.paymentPlan) {
      case "monthly":
        return 0;
      case "quarterly":
        return(formData.pincharBags * 80 + formData.pelletcharBags * 80)/10;
      case "yearly":
        return(formData.pincharBags * 80 + formData.pelletcharBags * 80)/5;
      default:
        return 0;
    }
  }

  const calculateSupervisorDiscount = () => {
    // return Math.round((formData.pincharBags * 80 + formData.pelletcharBags * 80)/6);
    return
  }

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Set Up Package"} content={"Set up your Black Bull Biochar package and start getting biochar delivered straight to your farm! Packages are made on a yearly basis. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module icon={"user"} name={"How Much Biochar Do You Need?"} spanColumn={18} spanRow={4} height={"100%"}>
              <div>
              Use our biochar calculator to get a rough estimate of how much pinchar and pelletchar you’ll be needing across the year. We recommend you get advice from our team if this is your first time.
              </div>
              <Calculator updateBiocharQty={updateBiocharQty}/>
            </Module>
            <Module spanColumn={6}>
             <ProductCounter fieldName={"pincharBags"} value={formData.pincharBags} handleChange={handleChange} name="Pinchar" price="£ 400 / bag" icon={pincharPlaceholder}/>    
            </Module>

            <Module spanColumn={6}>
              <ProductCounter disabled={true} fieldName={"pelletcharBags"} value={formData.pelletcharBags} handleChange={console.log("Pelletchar coming soon")} name="Pelletchar" price="£ 600 / bag" icon={pincharPlaceholder}/>    
            </Module>            
            <Module background={"#575757"} spanColumn={6}>
              <div className={styles.weightModule}>
                <span className={styles.weightExclamation}>exclamation-circle</span>
                  <div className={styles.weightText}>
                    You will need at least <span className={styles.weightAmount}>{Math.round(helpers.estimateBiocharStats(formData.pincharBags, formData.pelletcharBags, "monthly").volumeTotal)} m</span><span className={styles.weightAmountExp}>2</span>  of floor space
                  </div>
                </div>
            </Module>     
            <Module name={"Annual Price"}spanColumn={6} spanRow={2}>
              <div className={styles.invoiceContainer}>
                <div className={styles.invoiceTable}>
                  <div className={styles.invoiceRow}>
                    <div className={styles.invoiceItem}>Pinchar</div>
                    <div className={styles.invoicePrice}>£ {formData.pincharBags * 80}</div>
                  </div>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowWhite)}>
                    <div className={styles.invoiceItem}>Pelletchar</div>
                    <div className={styles.invoicePrice}>£ {formData.pelletcharBags * 270}</div>
                  </div>
                  <div className={styles.invoiceRow}>
                    <div className={styles.invoiceItem}>Discount</div>
                    <div className={styles.invoicePrice}>- £ 0{/*calculatePlanDiscount()*/}</div>
                  </div>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowWhite)}>
                    <div className={styles.invoiceItem}>BBB Discount</div>
                    <div className={styles.invoicePrice}>- £{formData.pincharBags * 80}{calculateSupervisorDiscount()}</div>
                  </div>
                  <div className={styles.invoiceLine}/>
                  <div className={helpers.clx(styles.invoiceRow, styles.invoiceRowBlack)}>
                    <div className={styles.invoiceItemBlack}>TOTAL</div>
                    <div className={styles.invoicePriceBlack}>£ {/*(formData.pelletcharBags * 270 + formData.pincharBags * 80) - calculatePlanDiscount() - calculateSupervisorDiscount()*/0}</div>
                  </div>
                </div>
                <Button name="Next" onPress={() => props.goToScreen("review-subscription", {formData: formData, addresses: addresses})} color="Grass" disabled={formData.paymentPlan === "" || formData.pincharBags === 0 && formData.pelletcharBags === 0 ? true : false}/>
              </div>
              
            </Module>                 
   
            <Module name={"Select Payment Plan"} spanColumn={18} height={"100%"}>
              <div>
              Depending on the payment plan you select, you may benefit from a discount. Your payment plan will not affect the frequency of your deliveries.
              </div>
              <PaymentPlans handleChange={handleChange} paymentPlan={formData.paymentPlan} selected={"monthly"}/>
            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSubscriptionScreen;
