import React, { useState, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { UserContext } from '../../UserContext.js';

import styles from './UpdateBillingDetailsScreen.module.css';
import helpers from '../../helpers.js';

import Nav from "../Nav.js";
import ScreenHeader from "../ScreenHeader.js";
import Module from "../Module.js";
import Button from "../Button.js";
import TextInput from "../TextInput.js";




function UpdateBillingDetailsScreen(props) {
  const {user, setUser} = useContext(UserContext);
  // console.log(route);
  let billingDetails = useLocation().state.billingDetails;
  // console.log(billingDetails);
  const [formData, setFormData] = useState(billingDetails);

  const handleChange = (formField, value) => {
    setFormData({ ...formData, [formField]: value });
  };


  const handleSubmit = async () => {
    if(true){ //FORM VALIDATION HERE
        const request = {
            method: "put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
            body: JSON.stringify(formData),
          };
      
          let response = await fetch(user.backEndURL + "/billing-details", request);
          let jsonResponse = await response.json();
          if (jsonResponse.success) {
            props.goToScreen("subscription");
          }else {
            // helpers.errorPrompt("Oops something went wrong while updating...", null, setPrompt, null)
          }
    } else {
        // helpers.errorPrompt("Passwords do not match!", "password2", setPrompt, setHighlighedtInput)
    }
    return;
  };


  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"subscription"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Subscription"} content={"Set up your Black Bull Biochar subscription and start getting biochar delivered straight to your farm! Subscriptions are made on a yearly basis, and deliveries are made each quarter. You may choose monthly, quarterly, or yearly billing."}/>
          <div className={"contentGrid"}>
            <Module icon={"money-bill"} name={"Update Billing Details"} spanColumn={24} spanRow={1} height={"100%"}>
              <div className={styles.contentWrapper}>
              <div className={styles.detailsRow}>
                  <TextInput name="Contact First Name" value={formData.firstName} onChange={(value) => handleChange("firstName", value)} labelStyle="top" placeholder="Enter First Name"/>
                  <TextInput name="Contact Last Name" value={formData.lastName} onChange={(value) => handleChange("lastName", value)} labelStyle="top" placeholder="Enter Last Name"/>
                </div>
                <div className={styles.detailsRow}>
                  <TextInput name="Contact Phone" value={formData.phone} onChange={(value) => handleChange("phone", value)} labelStyle="top" placeholder="Enter Phone"/>
                  <TextInput name="Contact Email" value={formData.email} onChange={(value) => handleChange("email", value)} labelStyle="top" placeholder="Enter Email"/>
                </div>
                <div className={styles.detailsRow}>
                  <TextInput name="Business Name / Addressee" value={formData.businessName} onChange={(value) => handleChange("businessName", value)} labelStyle="top" placeholder="Enter Business Name"/>
                  <TextInput name="Address Line 1" value={formData.addressLine1} onChange={(value) => handleChange("addressLine1", value)} labelStyle="top" placeholder="Enter Address Line 1"/>
                </div>
                <div className={styles.detailsRow}>
                  <TextInput name="City" value={formData.city} onChange={(value) => handleChange("city", value)} labelStyle="top" placeholder="Enter City"/>
                  <TextInput name="Address Line 2" value={formData.addressLine2} onChange={(value) => handleChange("addressLine2", value)} labelStyle="top" placeholder="Enter Address Line 2"/>
                </div>
                <div className={styles.detailsRow}>
                  <TextInput name="ZIP / Postal Code" value={formData.zip} onChange={(value) => handleChange("zip", value)} labelStyle="top" placeholder="Enter ZIP Code"/>
                  <TextInput name="County" value={formData.county} onChange={(value) => handleChange("county", value)} labelStyle="top" placeholder="Enter County"/>
                </div>
                <div className={styles.buttonRow}>
                    <Button onPress={() => props.goToScreen("subscription")} name={"Cancel"} color="Error"/>
                    <Button onPress={() => handleSubmit()} name={"Update"} color="Grass"/>
                </div>
              </div>
            </Module>

          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UpdateBillingDetailsScreen);
