
import styles from './TimelineScreen.module.css';
import helpers from '../helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from '../UserContext.js';

import Filters from './timeline/Filters.js'




import Nav from "../components/Nav.js";
import ScreenHeader from "../components/ScreenHeader.js";
import Module from "../components/Module.js";
import TextInput from "../components/TextInput.js";
import Selector from "../components/Selector";
import Button from "../components/Button.js";
import EventPopup from "../components/EventPopup.js";
import WeekPopup from "../components/WeekPopup.js";



function TimelineScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");

  const [events, setEvents] = useState([]);
  const [weekSlots, setWeekSlots] = useState([]);
  const [displayWeekPopup, setDisplayWeekPopup] = useState(false);
  const [displayedWeek, setDisplayedWeek] = useState(null)
  const [displayEventPopup, setDisplayEventPopup] = useState(false);
  const [editingEvent, setEditingEvent] = useState(false);


  
  const openWeekPopUp = () => {
    setDisplayWeekPopup(true);
  }

  const openNewEventPopUp = () => {
    setDisplayEventPopup(true);
  }

  const openEditEventPopUp = () => {
    setEditingEvent(true);
    setDisplayEventPopup(true);
  }
  
  const fetchFarmers = async () => {
    // const request = {
    //   method: "get",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + user.token,
    //   }
    // };
    // console.log(user.backEndURL);
    // let response = await fetch(user.backEndURL + "/users", request);
    // let jsonResponse = await response.json();
    // if (jsonResponse.success) {
    //   console.log(jsonResponse);
    //   // let newFarmers = [];
    //   // jsonResponse.data.forEach((farmer) => {
    //   //   let farmerDate = new Date(farmer.delivery_date)
    //   //   let dateString = farmerDate.getDay() + "/" + farmerDate.getMonth() + "/" + farmerDate.getYear();
    //   //   let newFarmer = {
    //   //     id: farmer._id,
    //   //     status: farmer.status,
    //   //     deliveryDate: dateString,
    //   //     pincharBags: farmer.products[0].amount,
    //   //     pelletcharBags: farmer.products[1].amount,
    //   //     weight: 2.2,
    //   //     volume: 8
    //   //   }
    //   //   newFarmers.push(newFarmer);
    //   // })

    //   setFarmers(jsonResponse.data);
    //   console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
    // } else {
    //   console.log("Fetch Subscription Failed...")
    // }
  }


  useEffect(() => {
    console.log("Fetching Farmers")
    
    fetchFarmers();
    initiateWeekSlots();
  },[user])

  const initiateWeekSlots = async () => {
    let today = new Date();
    let from = new Date(today.getTime());
    let to = new Date(today.getTime() + 57 *24*60*60*1000);
    let daysUntilSunday = 7 - today.getDay();
    let d = (to.getTime() - from.getTime())/ (24*60*60*1000);

    let nextSunday = new Date();
    nextSunday.setDate(today.getDate() + daysUntilSunday)
    let weekSlots = [];
    let j = 0;
    for(let i = nextSunday.getTime(); i < (to.getTime() + 7 * 24 * 60 * 60 * 1000); (i += 7 * 24 * 60 * 60 * 1000)){
      let startDate = new Date(i - 7 * 24 * 60 * 60 * 1000);
      startDate.setHours(23);
      startDate.setMinutes(59);
      startDate.setSeconds(59);
      startDate.setMilliseconds(999);
      let endDate = new Date(i)
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(999);
      let weekSlot = {
        startDate: startDate,
        endDate: endDate,
      }
      weekSlots.push(weekSlot);
      j++;
      if (j > 20){
        break;
      }
    }
    setWeekSlots(weekSlots);
  }

  const renderWeekEvents = (events) => {
    return(
      <>
       {
        events.map((event) => {          
          return (
            <div className={styles.eventRow} onClick={openEditEventPopUp}>
              <div className={styles.eventColumn1}>
                <div className={styles.iconEvent}>
                  truck
                </div>
                {event.order.farmerFirstName.substring(0,1)}. {event.order.farmerLastName}
              </div>
              <div className={styles.eventColumn2}>
                <div className={styles.iconEvent}>
                  shopping-bag
                </div>
                {event.order.bags}
              </div>
              <div className={styles.eventColumn3}>
                <div className={styles.eventDay}>
                  {event.order.pickupDate.toLocaleString('en-US', {weekday: "short"}).toUpperCase()}
                </div>
                {event.order.pickupDate.getDay()}
              </div>
            </div>
          )
        })
       }
      </>
    )
    
  }


  const renderWeekTiles = () => {
    return (
      <>
        {
          weekSlots.map((week) => {
            // console.log(week);
            let eventz = [
              {
                date: new Date(),
                type: "order",
                order: {
                  farmerFirstName: "Ian",
                  farmerLastName: "Wright",
                  bags: 22,
                  pickupDate: new Date(),
                  deliveryDate: new Date(),
                  id: "ORD-2428",
                  deliveryId: "DEL-00013",
                  site: "ARA",
                  comment: "Check with Ian whether he’ll be available in the afternoon. Lorry driver should be notified early on as to whether or not he needs to contact Ian or another person."

                }
              },
              {
                date: new Date(),
                type: "order",
                order: {
                  farmerFirstName: "Jonathan",
                  farmerLastName: "Lloyd",
                  bags: 13,
                  pickupDate: new Date(),
                  deliveryDate: new Date(),
                  id: "ORD-2429",
                  deliveryId: "DEL-00013",
                  site: "JNR",
                  comment: "Check with Ian whether he’ll be available in the afternoon. Lorry driver should be notified early on as to whether or not he needs to contact Ian or another person."
                }
              },
              
            ]

            // CHANGE CODE HERE ONCE YOU HAVE EVENTS CONNECTED
            let updatedarray = (prevEvents) => setEvents([...prevEvents, eventz])

            week.events = eventz;
            week.notes = "A Hello, World! program is generally a simple computer program which outputs (or displays) to the screen (often the console) a message similar.";

            return(
              <div className={styles.weekSlot}>
                <div className={styles.weekSlotHeaderRow} onClick={() => {setDisplayWeekPopup(true); setDisplayedWeek(week)}}>
                  <div className={styles.weekSlotDate}>
                    {formatDate(week.startDate)}
                  </div>
                  <div className={styles.weekSlotOpenIconContainer}>
                    arrow-up-right-from-square
                  </div>
                </div>
                <div className={styles.weekSlotInventoryContainer}>
                  <div className={styles.weekSlotInventoryHeaderRow}>
                    <div className={styles.weekSlotInventoryHeaderColumn1}>
                      Inventory
                    </div>
                    <div className={styles.weekSlotInventoryHeaderColumn2}>
                      ARA
                    </div>
                    <div className={styles.weekSlotInventoryHeaderColumn3}>
                      JNR
                    </div>
                  </div>
                  <div className={helpers.clx(styles.weekSlotInventoryRow)}>
                    <div className={styles.weekSlotInventoryColumn1}>
                      Weekly Prod.
                    </div>
                    <div className={styles.weekSlotInventoryColumn2}>
                      0
                    </div>
                    <div className={styles.weekSlotInventoryColumn3}>
                      3
                    </div>
                  </div>
                  <div className={helpers.clx(styles.weekSlotInventoryRow, styles.weekSlotInventoryRowTransparent)}>
                    <div className={styles.weekSlotInventoryColumn1}>
                      Estimated Prod.
                    </div>
                    <div className={styles.weekSlotInventoryColumn2}>
                      0
                    </div>
                    <div className={styles.weekSlotInventoryColumn3}>
                      3
                    </div>
                  </div>
                  <div className={helpers.clx(styles.weekSlotInventoryRow)}>
                    <div className={styles.weekSlotInventoryColumn1}>
                      Cumulative Prod.
                    </div>
                    <div className={styles.weekSlotInventoryColumn2}>
                      0
                    </div>
                    <div className={styles.weekSlotInventoryColumn3}>
                      3
                    </div>
                  </div>
                  <div className={styles.weekSlotLine}/>
                </div>
                <div className={styles.eventsContainer}>
                  <div className={styles.eventsContent}>
                    {renderWeekEvents(week.events)}
                  </div>
                  <div className={styles.addEventButton} onClick={openNewEventPopUp}>
                    + Add Event
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} isAdmin={true} currentScreen={"admin/timeline"}/>

      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <EventPopup displayEventPopup={displayEventPopup} editingEvent={editingEvent} closePopup={() => {setDisplayEventPopup(false); setEditingEvent(false)}}/>
          {
            displayWeekPopup ? 
              <WeekPopup displayWeekPopup={displayWeekPopup} displayedWeek={displayedWeek} weekSlots={weekSlots} events={events} setDisplayedWeek={setDisplayedWeek} closePopup={() => {setDisplayWeekPopup(false)}}/>
            :
              null
          }
          <ScreenHeader name={"Timeline"} content={""}/>
          <div className={styles.mainContainer}>
            <Filters/>
            <div className={styles.calendarMainContainer}>
              <div className={styles.headerRow}>
                <div className={styles.header}>
                    Weekly Calendar
                </div>
                <div className={styles.dateFilterContainer}>
                  <div className={styles.dateLabel}>
                    From:
                  </div>
                  <input type={"date"} className={styles.date}/>
                </div>
                <div>
                  <div className={styles.dateLabel}>
                    To:
                  </div>
                  <input type={"date"} className={styles.date}/>
                </div>
                <div className={styles.sitesInventoryContainer}>
                  <div className={styles.siteContainer}>
                    <div className={styles.siteLeftColumn}>
                      JNR
                    </div>
                    <div className={styles.siteRightColumn}>
                      <span className={styles.inventoryValue}> 20</span> /49
                    </div>
                  </div>
                  <div className={styles.siteContainer}>
                    <div className={styles.siteLeftColumn}>
                      ARA
                    </div>
                    <div className={styles.siteRightColumn}>
                      <span className={styles.inventoryValue}> 20</span> /30
                    </div>
                  </div>
                </div>
              </div>
             
              <div className={styles.calendarGridContainer}>
                {renderWeekTiles()}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
//

export default TimelineScreen;

function formatDate(date) {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  
  // Function to add ordinal suffix to day (e.g., 1st, 2nd, 3rd, etc.)
  function getOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1: return day + "st";
      case 2: return day + "nd";
      case 3: return day + "rd";
      default: return day + "th";
    }
  }
  
  const formattedDate = `${month} ${getOrdinalSuffix(day)}, ${year}`;
  return formattedDate;
}
