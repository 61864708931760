import styles from './Calculator.module.css'; //styles
import helpers from '../../helpers.js';
import React, {useState, useEffect } from 'react';
import Slider from "../Slider.js";
import Checkbox from "../Checkbox.js";
import Button from "../Button.js";



/*
    CHECKBOX
        props:
          
*/
const Calculator = (props) => {
    const [formData, setFormData] = useState({
        biocharToBedding: false,
        beddingToManure: false,
        biocharToManure: false,
        biocharToSlurry: false,
        beddingArea: 0,
        beddingPeriod: 0,
        manureQty: 0,
        slurryQty: 0
    });
    const progress = props.progress;
    const maxValue = props.progressMax;


    const handleChange = (formField, value) => {
        setFormData({ ...formData, [formField]: value });
      };

    const estimateBiochar = async () => {
        let pincharBags = Math.round(formData.beddingArea * formData.beddingPeriod / 35);
        let pelletcharBags = Math.round((formData.manureQty / 5) + (formData.slurryQty / 5));
        props.updateBiocharQty(pincharBags, pelletcharBags);
    };

    // useEffect(() => {
    //     let intervalID = setInterval(function () {
    //         console.log("Here");
    //         setProgressCount(progressCount + 1);
    //         if (progressCount === progress) {
    //             window.clearInterval(intervalID);
    //         }
    //     }, 150);
    //   }, []);

    return(
        <div className={styles.calculatorContainer}>
            <div className={styles.inputsContainer}>
                <div className={styles.inputColumn}>
                    <div className={helpers.clx(styles.inputModule, formData.biocharToBedding ? "" : styles.disabled)}>
                        <Checkbox onPress={() => handleChange("biocharToBedding", !formData.biocharToBedding)} checked={formData.biocharToBedding}  text={"I want to add biochar to bedding"}/>
                        <Slider value={formData.beddingArea} name={"beddingArea"} handleChange={handleChange} minRange={0} maxRange={500}/>
                        <Slider value={formData.beddingPeriod} name={"beddingPeriod"} handleChange={handleChange} minRange={0} maxRange={52}/>
                        <Checkbox onPress={() => handleChange("beddingToManure", !formData.beddingToManure)} checked={formData.beddingToManure}  text={"I wish to add the bedding into manure"}/>
                    </div>
                </div>
                <div className={styles.inputColumn}>
                    <div className={helpers.clx(styles.inputModule, formData.biocharToManure ? "" : styles.disabled)}>
                        <Checkbox onPress={() => handleChange("biocharToManure", !formData.biocharToManure)} checked={formData.biocharToManure}  text={"I want to add biochar directly to manure"}/>
                        <Slider value={formData.manureQty} name={"manureQty"} handleChange={handleChange} minRange={0} maxRange={1000}/>
                    </div>
                    <div className={helpers.clx(styles.inputModule, formData.biocharToSlurry ? "" : styles.disabled)}>
                        <Checkbox onPress={() => handleChange("biocharToSlurry", !formData.biocharToSlurry)} checked={formData.biocharToSlurry}  text={"I want to add biochar to slurry"}/>
                        <Slider value={formData.slurryQty} name={"slurryQty"} handleChange={handleChange} minRange={0} maxRange={1000}/>
                    </div>
                </div>
            </div>
            <div className={styles.buttonRow}>
                <Button name="Calculate Estimation" color={"Coal"} onPress={estimateBiochar}/>
            </div>
            

        </div>
    )
}

Calculator.defaultProps = {
    checked: false,
    onPress: console.log("Set CheckBox onPress"),
    text: "Change checkbox text prop",
    inverted: false
  };

export default  React.memo(Calculator);
