import {useState} from "react";

import styles from './TextInput.module.css'; //styles
import helpers from '../helpers.js';

import eyePassword1 from "../assets/images/eyePassword1.png";
import eyePassword2 from "../assets/images/eyePassword2.png";




/*
    STANDARD TEXT INPUT
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onChange: setter from parent
            height: sets height, and turns it into textarea
            customStyle: TODO based on future requirements
*/
const TextInput = (props) => {
    const [displayPassword, setDisplayPassword] = useState(false);
    const [displayEye, setDisplayEye] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    console.log(props.iconCustom)
    return(
        <div style={props.customStyle} className={helpers.clx(styles.mainContainer, props.labelStyle=== "top" ? styles.mainContainerLabelTop: "")} onMouseEnter={() => setDisplayEye(true)} onMouseLeave={() => setDisplayEye(false)}>
            <div className={helpers.clx(styles.labelContainer, props.highlighted ? styles.highlightedLabel : "" )}>
                <div className={styles.labelIcon}>
                  {props.iconName}
                </div>
                {props.iconCustom ? 
                    <img src={props.iconCustom} className={styles.customIcon}/>
                  :
                     null
                }
                {props.name}
            </div>
            {props.isPassword && passwordFocused || props.isPassword && displayEye ? 
                    <img src={displayPassword ? eyePassword1 : eyePassword2} className={styles.passwordDisplayIcon} onClick={() => {setDisplayPassword(!displayPassword)}}/>
                : 
                    null
            }
            {props.height === "" ?
                <input style={{height: props.height !== "" ? props.height: null}} type={props.isPassword && !displayPassword ? "password" : "text"} placeholder={props.placeholder} className={helpers.clx(styles.textInput, props.isPassword && !displayPassword ? styles.password : "", props.highlighted ? styles.highlightedInput : "")} value={props.value} onChange={(e) => props.onChange(e.target.value)} onFocus={() => setPasswordFocused(true)} onBlur={() => setPasswordFocused(false)}/>
                :
                <textarea style={{height: props.height !== "" ? props.height: null}} type={props.isPassword ? "password" : "text"} placeholder={props.placeholder} className={helpers.clx(styles.textInput, props.isPassword ? styles.password : "", props.highlighted ? styles.highlightedInput : "")} value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
            }
        </div>
    )
}

TextInput.defaultProps = {
    highlighted: false,
    name: 'InputName',
    height: "",
    placeholder: "Change Placeholder Prop", 
    labelStyle: 'left', //top left
    onChange: console.log("Change onChangeProp"), //set setState from parent
    iconName: "", //font-awesome 5 icon name
    isPassword: false,
    highlighted: false
  };

export default TextInput;