import React from 'react';
import styles from './DottedLine.module.css'; //styles



/*
    DottedLine
        props:
            name: str, name and label of input
            placeholder: str, placeholder text
            labelStyle: default is on the left, top has the label above the input
            iconName: font awesome icon name, default is no icon
            value: input value (state) from parent
            onPress: setter from parent
            customStyle: TODO based on future requirements
*/
const DottedLine = (props) => {
    console.log("DOTTELD LINE RENDER")

    const returnLineLength = () => {
        let segmentHeightRem = props.segmentHeight.split("rem")[0];
        return props.segmentNumber * (segmentHeightRem * 1.6) + "rem";
    }

    return(
            <div className={styles.segmentsContainer} style={{height: returnLineLength()}}>
                {(() => {
                        let segments = [];
                        for(let i = 0; i < props.segmentNumber; i++){
                            segments.push(<div className={styles.segment} style={{width: props.segmentWidth, height: props.segmentHeight, background: 'var(--' + props.color + ')'}}/>)
                        }
                        return segments;
                    })()
                }
            </div>
    )
}

DottedLine.defaultProps = {
    segmentNumber: 5,
    segmentHeight: '0.2rem',
    segmentWidth: '0.7rem',
    color: 'grass'
  };

export default React.memo(DottedLine);