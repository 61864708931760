import React, {useMemo, useState} from 'react';
import styles from './QuestionnaireContent.module.css'; //styles
import helpers from '../../helpers.js';

import TagSelector from "../TagSelector.js";
import Checkbox from "../Checkbox.js";
import Button from "../Button.js";

import bbbLogo from "../../assets/images/bbbLogo.png";
import countries from "../../assets/Countries.js";

const QuestionnaireContent = (props) => {

    return(
        <> 
            <div className={styles.logoContainer}>
                <img src={bbbLogo} className={styles.bbbLogo} alt="BBB Logo"/>
            </div>
            <div className={helpers.clx(styles.questionaireContent, props.transitioning ? styles.questionaireContentHidden : "")}>
                <h2 className={styles.questionaireHeader}>
                    Tell Us More About Yourself!
                </h2>
                <div className={styles.questionRow}>
                    <TagSelector name={props.formData.questionnaireData[0].questionName} tags={props.formData.questionnaireData[0].tags} selectedTags={props.formData.questionnaireData[0].selectedTags} updateTags={(tag) => props.updateQuestionnaireData(0,tag)}/>
                </div>
                <div className={styles.questionRow}>
                    <TagSelector name={props.formData.questionnaireData[1].questionName} tags={props.formData.questionnaireData[1].tags} selectedTags={props.formData.questionnaireData[1].selectedTags} updateTags={(tag) => props.updateQuestionnaireData(1,tag)}/>
                </div>
                <div className={styles.checkboxRow}>
                    <Checkbox text={"I do organic farming"} checked={props.formData.isOrganic} onPress={() => props.handleChange("isOrganic", !props.formData.isOrganic)}/>
                    <Checkbox text={"I do conventional farming"} checked={props.formData.isConventional} onPress={() => props.handleChange("isConventional", !props.formData.isConventional)}/>
                </div>
                <div className={styles.buttonRow}>
                    <Button disabled={false} onPress={props.prevStep} name="Back" color="Error"/>
                    <Button disabled={false} onPress={props.nextStep} name="Next" color="Coal"/>
                </div>
                
            </div>
        </>
    )
}


export default React.memo(QuestionnaireContent);