import styles from './Gauge.module.css'; //styles
// import helpers from '../helpers.js';
import React, {useState, useEffect } from 'react';



/*
    CHECKBOX
        props:
          
*/
const Gauge = (props) => {
    const [progressCount, setProgressCount] = useState(0);
    const progress = props.progress;
    const maxValue = props.progressMax;


    // useEffect(() => {
    //     let intervalID = setInterval(function () {
    //         console.log("Here");
    //         setProgressCount(progressCount + 1);
    //         if (progressCount === progress) {
    //             window.clearInterval(intervalID);
    //         }
    //     }, 150);
    //   }, []);
    
    

    return(
        <div className="spin-gauge-container">
            <div className={styles.gaugeBg} style={{"background": "conic-gradient(#34B61F 0.00% " + progress * 100 /maxValue + "%, #B0E000 0.00% 0.55%)"}}>

                <div className={styles.gaugeMiddle}>
                    <span>{progress}/{maxValue}</span>
                    <span className={styles.gaugeTextBags}>Bags</span>
                </div>

            </div>


        </div>
    )
}

Gauge.defaultProps = {
    checked: false,
    onPress: console.log("Set CheckBox onPress"),
    text: "Change checkbox text prop",
    inverted: false
  };

export default  React.memo(Gauge);
