import styles from './ResetPassword.module.css'; //styles
import { useState, useEffect, useCallback, useContext } from 'react';

import { UserContext } from "../../UserContext.js";


import TextInput from '../TextInput.js';
import Button from '../Button.js';
import helpers from '../../helpers.js';


const ResetPassword = (props) => {
    const {user, setUser} = useContext(UserContext);

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [prompt, setPrompt] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);
    const [highlighedtInput, setHighlighedtInput] = useState("");

    // const [positive, setPositive] = useState(false);
    // const [password, setPassword] = useState("");
    // const [passwordConfirm, setPasswordConfirm] = useState("");
    const [tokenValid, setTokenValid] = useState(false);
    const clearPrompt = () => setPrompt("");
    
    const query = new URLSearchParams(window.location.search)
    const token = query.get("token");
    
    useEffect(() => {
      async function fetchData() {
        const request = {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
          }),
        };
  
        let response = await fetch(user.backEndURL + "/reset/check", request);
        let jsonResponse = await response.json();
        if (!jsonResponse.success) {
          setTokenValid(false);
        //   setPositive(false);
          setPrompt(
            "Your password reset link is invalid. Please request a new one."
          );
          return;
        } else {
          setTokenValid(true);
        }
      }
      fetchData();
    }, [token, user]);

    const handleSubmit = async () => {
        if(password1===password2){
            const request = {
                method: "put",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  newPassword: password1,
                  token: token,
                }),
              };
          
              let response = await fetch(user.backEndURL + "/reset/update", request);
              let jsonResponse = await response.json();
              if (jsonResponse.success) {
                setPasswordReset(true);
              }else {
                helpers.errorPrompt("Oops something went wrong...", null, setPrompt, null)
              }
        } else {
            helpers.errorPrompt("Passwords do not match!", "password2", setPrompt, setHighlighedtInput)
        }
        // if(helpers.isValidEmail(email)){

        //     //API CALL HERE
        //     setEmailSent(true);
        //     setPrompt("You will shortly receive an email at " + email + " with instructions on how to reset your password");
        // }else{
        //     helpers.errorPrompt("Please Enter Valid Email", "email", setPrompt, setHighlighedtInput)
        // }
        return;
    };


    return(
        <div className={styles.container}>
            {
                !passwordReset ? 
                <>
                      <h2 className={styles.header}>
                        Reset Your Password
                    </h2>
                    <div className={styles.subHeader}>
                    Please enter your new password. It must be between 6 and 64 characters.            </div>
                    <div className={styles.inputWrapper}>
                    <TextInput name={"New Password"} value={password1} onChange={setPassword1} placeholder={"Enter Password"} labelStyle={"top"} highlighted={highlighedtInput === "password1"} isPassword={true}/>
                    </div>
                    <div className={styles.inputWrapper}>
                    <TextInput name={"Confirm New Password"} value={password2} onChange={setPassword2} placeholder={"Enter Password"} labelStyle={"top"} highlighted={highlighedtInput === "password1"} isPassword={true}/>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <Button name={"Submit"} color={"Grass"} onPress={handleSubmit} disabled={passwordReset || !tokenValid}/>
                    </div>
                    <div className={helpers.clx("errorPrompt", styles.prompt, passwordReset ? styles.promptConfirmation : "")}>
                        {prompt}
                    </div>
                </>
                :
                <>
                   <h2 className={styles.header}>
                        Password Successfully Reset!
                    </h2>
                    <div className={styles.subHeader}>
                    You may now login into your account using your new password.          </div>
             
                    <Button name={"Back To Login"} color={"Coal"} onPress={props.returnToLogin}/>

                    <div className={helpers.clx("errorPrompt", styles.prompt, passwordReset ? styles.promptConfirmation : "")}>
                        {prompt}
                    </div>
                </>
            }
        </div>
    )
}


export default ResetPassword;