import styles from './CharcodeTag.module.css'; //styles
import helpers from '../helpers.js';
import { useState } from 'react';

import CharcodeTooltip from "./CharcodeTooltip.js";



/*
    CHECKBOX
        props:
            checked: boolean, sets checkbox status (checked, unchecked)
            onPress: function, action carried out when checked/unchecked
            label: str, label
            inverted: bool, if true sets label before checkbox 
*/
const CharcodeTag = (props) => {
    const [displayTip, setDisplayTip] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    // useEffect(() => { OTHER COLORS?
        
    // }, [props.color])

    const showTooltip = (e) => {
        setDisplayTip(true);
        setPosition({ x: e.clientX, y: e.clientY });
      };

    return(
        <div className={styles.container} onMouseEnter={showTooltip} onMouseLeave={() => setDisplayTip(false)}> 
            <div className={styles.text}>#{props.charcodeId}</div>
            {displayTip ? <CharcodeTooltip displayTip={displayTip} position={position} charcodeId={props.charcodeId}/> : ""}
        </div>
    )
}

CharcodeTag.defaultProps = {
    charcodeId: "CHA-000000"
  };

export default CharcodeTag;