
import styles from './OrdersScreen.module.css';
import helpers from './helpers.js';
import {useState, useContext, useEffect} from 'react';
import { UserContext } from './UserContext.js';

import Nav from "./components/Nav.js";
import ScreenHeader from "./components/ScreenHeader.js";
import Module from "./components/Module.js";
import TextInput from "./components/TextInput.js";
import Button from "./components/Button.js";
import FAQ from "./components/support/FAQ.js";

const ordersArray = [
  {
    id: "",
    status: "delivered",
    deliveryDate: "",
    pincharBags: "",
    pelletcharBags: "",
    weight: "",
    volume: ""
  }
]



function OrdersScreen(props) {
  const {user, setUser} = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [orders, setOrders] = useState(ordersArray);

  useEffect(() => {
    console.log("Fetching Orders")
    const fetchOrders = async () => {
      const request = {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      };
      let response = await fetch(user.backEndURL + "/orders", request);
      let jsonResponse = await response.json();
      if (jsonResponse.success) {
        console.log(jsonResponse.data);
        let newOrders = [];
        jsonResponse.data.forEach((order) => {
          let orderDate = new Date(order.delivery_date)
          let dateString = orderDate.getDay() + "/" + (orderDate.getMonth()+1) + "/" + orderDate.getFullYear();
          let newOrder = {
            id: order._id,
            status: order.status,
            deliveryDate: dateString,
            pincharBags: order.products[0].amount,
            pelletcharBags: 0,//order.products[1].amount,
            weight: Math.round(order.products[0].amount * 0.32 * 10)/10,
            volume: order.products[0].amount * 2
          }
          newOrders.push(newOrder);
        })

        setOrders(newOrders);
        console.log("SILENTLY COMPLETES ITS MISSION, LIKE A NINJA");
      } else {
        console.log("Fetch Subscription Failed...")
      }
      
    }
    fetchOrders();
  },[])

  const renderOrders = () => {
    let filteredOrders = [];
    if(selectedFilter === "all"){
      filteredOrders = orders;
    }else {
      filteredOrders = orders.filter(function (order) {
        return order.status === selectedFilter 
      }
    );
  }

  const renderStatus = (status) => {
    switch(status){
      case "delivered":
        return <td className={styles.statusDelivered}><span className={styles.statusIcon}>check-circle</span>Delivered</td>
      case "upcoming":
        return <td className={styles.statusUpcoming}><span className={styles.statusIcon}>arrow-circle-right</span>Upcoming</td>
      case "cancelled":
        return <td className={styles.statusCancelled}><span className={styles.statusIcon}>times-circle</span>Cancelled</td>
      default:
          return <td>N/A</td>
    }

  }
 

  return(
    <>
      {filteredOrders.map(order => {
        return(
          <tr className={styles.tableRow}>
            <td className={styles.dataBold}>#{order.id}</td>
            {renderStatus(order.status)}
            <td>{order.deliveryDate}</td>
            <td><span className={styles.dataBold}>{order.pincharBags} </span>Bags</td>
            <td><span className={styles.dataBold}>{order.pelletcharBags}</span> Bags</td>
            <td><span className={styles.dataBold}>{order.weight} </span>tons</td>
            <td><span className={styles.dataBold}>{order.volume}</span> m<span className={styles.exp}>3</span></td>
          </tr>
        ) 
      })
    }
    </>
  )
}
  return (
    <div className={helpers.clx("mainBlackContainer", "mainBlackContainerLoggedIn")}>
      <Nav goToScreen={props.goToScreen} currentScreen={"orders"}/>
      <div className={helpers.clx("mainWhiteContainer", "mainWhiteContainerLoggedIn")}>
        <div className={helpers.clx("mainWhiteContainerContent", props.screenTransitioning ? "mainWhiteContainerContentHidden" : "")}>
          <ScreenHeader name={"Orders"} content={"Browse through your biochar orders. Don’t hesitate to contact us if you have any questions!"}/>
          <div className={"contentGrid"}>
            <Module spanColumn={24} spanRow={5} height={"100%"}>
              <div className={styles.headerRow}>
                <div onClick={() => setSelectedFilter("all")} className={helpers.clx(styles.headerFilter, selectedFilter === "all" ? styles.headerFilterSelected : "")}>
                  All
                </div>
                <div onClick={() => setSelectedFilter("upcoming")} className={helpers.clx(styles.headerFilter, selectedFilter === "upcoming" ? styles.headerFilterSelected : "")}>
                  Upcoming
                </div>
                <div onClick={() => setSelectedFilter("delivered")} className={helpers.clx(styles.headerFilter, selectedFilter === "delivered" ? styles.headerFilterSelected : "")}>
                  Delivered
                </div>
                <div onClick={() => setSelectedFilter("cancelled")} className={helpers.clx(styles.headerFilter, selectedFilter === "cancelled" ? styles.headerFilterSelected : "")}>
                  Cancelled
                </div>
              </div>
              <table className={styles.orderTable}>
                <tr className={styles.orderHeaderRow}>
                  <th>Order ID</th>
                  <th>Status</th>
                  <th>Delivery Date</th>
                  <th>Pinchar</th>
                  <th>Pelletchar</th>
                  <th>Weight</th>
                  <th>Volume</th>
                </tr>
                {renderOrders()}
              </table>

            </Module>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersScreen;
